
//import tags
import { Container, Row, Col } from "react-bootstrap";

function Error404() {

    return (
        <div className='main'>
            <Container>
                <Row>
                    <Col xxl={12}>
                    <h2>404 - Page Not Found</h2>
                <p>Sorry, the page you are looking for does not exist.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
    
}

export default Error404;