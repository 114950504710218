import React, { useState, useEffect } from 'react';
import { useAppContext } from './../components/Context';

//import tags
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'

import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

//import resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';


const containerStyle = {
    width: '400px',
    height: '400px'
};

const center = {
    lat: -3.745,
    lng: -38.523
};


function Contact() {


    const { globalContact } = useAppContext();

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "YOUR_API_KEY"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])


    return isLoaded ? (
        <div className='main'>

            <section className="breadcumbSection" id="sectionBreadCumb">
                <Container>
                    <Row>
                        <Col xxl={12}>
                            <div className="breadcumb">
                                <div className="breadcumbLeft">
                                    <h1>კონტაქტი</h1>
                                </div>
                                <div className="breadcumbRight">
                                    <ul>
                                        <li><Link to="/">მთავარი</Link></li>
                                        <li><FontAwesomeIcon icon={faChevronRight} /></li>
                                        <li>კონტაქტი</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="contactSection">
                <Container>
                    <Row>

                        <Col xxl={8}>
                            <div className="contactLeft">
                                <h2>საკონტაქტო ფორმა</h2>
                                <p>საიტის გამოყენებასთან დაკავშირებით კითხვების შემთხვევაში, მოგვწერე ქვემოთ მოცემული საკონტაქტო ფორმის საშუალებით.</p>
                                <div className="contactForm">
                                    <Row>
                                        <Col xxl={6}>
                                            <div className="contactFormGroup">
                                                <label>თქვენი სახელი, გვარი</label>
                                                <input type="text" name="name" placeholder="სახელი, გვარი" />
                                            </div>
                                        </Col>
                                        <Col xxl={6}>
                                            <div className="contactFormGroup">
                                                <label>ელ.ფოსტა</label>
                                                <input type="email" name="email" placeholder="ელ.ფოსტა" />
                                            </div>
                                        </Col>
                                        <Col xxl={6}>
                                            <div className="contactFormGroup">
                                                <label>ტელეფონი</label>
                                                <input type="text" name="phone" placeholder="ტელეფონი" />
                                            </div>
                                        </Col>
                                        <Col xxl={6}>
                                            <div className="contactFormGroup">
                                                <label>თემა</label>
                                                <input type="text" name="subject" placeholder="თემა" />
                                            </div>
                                        </Col>
                                        <Col xxl={12}>
                                            <div className="contactFormGroup">
                                                <label>შეტყობინება</label>
                                                <textarea name="message" placeholder="თქვენი შეტყობინება..."></textarea>
                                            </div>
                                        </Col>
                                        <Col xxl={12}>
                                            <div className="contactFormGroup">
                                                <button className="sendRequest">შეტყობინების გაგზავნა</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xxl={4}>
                            <div className="contactRight">
                                <h2>საკონტაქტო ინფორმაცია</h2>
                                <p>დაგვიკავშირდით საკონტაქტო ინფორმაციის საშუალებით და ჩვენ დაგეხმარებით.</p>
                                <div className="contactSingleBox">
                                    <h3>მისამართი</h3>
                                    <Link to="/">{ typeof globalContact == 'object' ? globalContact?.translations[0].address : '' }</Link>
                                </div>
                                <div className="contactSingleBox">
                                    <h3>ტელეფონი</h3>
                                    <Link to="/">{ typeof globalContact == 'object' ? globalContact?.phone : '' }</Link><br />
                                    <Link to="/">{ typeof globalContact == 'object' ? globalContact?.phone2 : '' }</Link>
                                </div>
                                <div className="contactSingleBox">
                                    <h3>ელ.ფოსტა</h3>
                                    <Link to="/">{ typeof globalContact == 'object' ? globalContact?.email : '' }</Link>
                                </div>
                                <div className="contactSingleBox">
                                    <h3>საიდენტიფიკაციო კოდი</h3>
                                    <Link>{ typeof globalContact == 'object' ? globalContact?.idnum : '' }</Link><br />
                                </div>
                            </div>
                        </Col>

                        <Col xxl={12}>
                            <div className="map">
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={10}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                >
                                    { /* Child components, such as markers, info windows, etc. */}
                                    <></>
                                </GoogleMap>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </div>
    ) : <></>

}

export default Contact;