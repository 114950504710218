import { useState } from 'react'

//import tags
import { Container, Image, Row, Col, Tab, Tabs, Accordion } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


function Sidemenu(props) {


    return (
        <div className="sidebarContainer">
            <div className="closeSidebarModal" onClick={() => props.setSideMenuModal(false)}>
                <FontAwesomeIcon icon={faTimes} />
            </div>

            <div className="menuInner">
                <div className="sideMenuHeader">
                    <h4>მენიუ</h4>
                </div>

                <div className="mainSideMenuBox">
                    <Accordion>
                        
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>უძრავი ქონება</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/photovideo">ფოტო / ვიდეო გადაღება</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/rate">შეფასება</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/management">უძრავი ქონების მართვა/მენეჯმენტი</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/exclusive">ექსკლუზიური მომსახურება</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/check">ყიდვამდე გადამოწმება</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/insurance">დაზღვევა</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/legalservice">იურიდიული მომსახურება</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/ads">რეკლამა სოც. მედიაში</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/clients">კლიენტი</Link></li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>არქიტექტურა - დიზაინი</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/repairservice">სრული სარემონტო მომსახურება</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/montage">ნებისმიერი სირთულის რკინა ბეტონის მშენებლობა/მონტაჟი</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/landservice">მიწის სამუშაოები</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/buildingmanagement">მშენებლობის მენეჯმენტი</Link></li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>იურიდიული მომსახურება</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/architect">არქიტექტურული საპროექტო დოკუმენტაციის მომზადება</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/urban">ქალაქგეგმარებითი ურბანული პროექტის შედგენა</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/costservice">მშენებლობის ხარჯთაღრიცხვა</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/interiordesign">ინტერიერის დიზაინი</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/communications">საინჟინრო კომუნიკაციების პროექტირება</Link></li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>კომპანია</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/about">ჩვენ შესახებ</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/newses">სიახლეები</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/cities">პოპულარული ქალაქები</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/videos">ვიდეო სიახლეები</Link></li>
                                    <li><Link onClick={() => props.setSideMenuModal(false)} to="/contact">კონტაქტი</Link></li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div>

            </div>

        </div>
    )

}

export default Sidemenu;