import {useState, useEffect} from 'react';

// import tags
import {Container, Row, Col, Image} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton'
import moment from 'moment';
import { Link } from "react-router-dom";
import slugify from 'slugify';

//import resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

//api
import { get_news } from '../../api/Api'

function Main() {

    const baseUrl = 'https://reluxapi.myweb.com.ge';
    const [news, setNews] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          try {
            get_news(setNews);
    
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        
        if(!news) {
            fetchData();
        }
    
      }, [news]);

    return (
        <div className='main'>
            <section className="breadcumbSection" id="sectionBreadCumb">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="breadcumb">
                                <div className="breadcumbLeft">
                                    <h1>სიახლეები</h1>
                                </div>
                                <div className="breadcumbRight">
                                    <ul>
                                        <li>
                                            <a href="/">მთავარი</a>
                                        </li>
                                        <li>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                <path fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                                            </svg>
                                        </li>
                                        <li>სიახლეები</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='mainNews'>
                <Container>
                    <Row>
                        { 
                            typeof news === 'object' ? 
                                news.map(function(item) {
                                    return(
                                        <Col key={item.id} xxl={4}>
                                            <Link to={"/news/"+item.id+"/"+slugify(item.translations[0].title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g })}>
                                                <div className='newsCard'>
                                                    <div className='newsImage'>
                                                        <Image src={baseUrl+'/storage/news/'+item.image} alt={item.translations[0].title} />                                                
                                                    </div>
                                                    
                                                    <h4>{item.translations[0].title}</h4>
                                                    <div className='newsDate'><FontAwesomeIcon className='me-2' icon={faCalendar} /> {moment(item.updated_at).format('DD/MM/YYYY HH:mm')}</div>
                                                    <div className='newsDescription'>{(item.translations[0].text).length > 150 ? ((item.translations[0].text).substring(0, 150)).replace(/<[^>]*>/g, '') + '...' : (item.translations[0].text).replace(/<[^>]*>/g, '')}</div>
                                                </div>
                                            </Link>
                                        </Col>
                                    );
                                })
                            : 

                            <>
                                <Col xxl={4}>
                                    <Skeleton count={4} style={{ width: '100%' }} />
                                </Col>
                                
                                <Col xxl={4}>
                                    <Skeleton count={4} style={{ width: '100%' }} />
                                </Col>
                                <Col xxl={4}>
                                    <Skeleton count={4} style={{ width: '100%' }} />
                                </Col>
                            </>
                            
                        }
                        
                        
                    </Row>
                </Container>
            </div>
        </div>
    )

}

export default Main;
