
import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../components/Context';

//import tags
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";


//import resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';


//import components
import ProfileMenu from './../../components/dynamic/ProfileMenu';


//import api

import { update_user } from './../../api/Api'


function Main() {
    const { isLoggedIn, updateUserContext, updatedUser } = useAppContext();
    
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRePassword] = useState('');

    const [loading, setLoadgin] = useState(false);
    const [message, setMessage] = useState(false);

    useEffect(()=> {
        setFname(isLoggedIn?.fname);
        setLname(isLoggedIn?.lname);
        setEmail(isLoggedIn?.email);
        setPhone(isLoggedIn?.phone);

        setLoadgin(false);

    }, [isLoggedIn])


    useEffect(() => {
        if(message) {
            updateUserContext();
        }

    }, [message]);

    const updateUser = async () => {


        if(sessionStorage.hasOwnProperty('token')) {

            setLoadgin(true);

            const data = {
                'fname': fname,
                'lname': lname,
                'email': email,
                'phone': phone
            }
    
            const header = {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }

            try {
                await update_user(setMessage, data, header);
            } catch (error) {
                console.error('Error fetching data:', error);
            }

        }

        
    }

    return (
        <div className='main'>

            <section className="breadcumbSection" id="sectionBreadCumb">
                <Container>
                    <Row>
                        <Col xxl={12}>
                            <div className="breadcumb">
                                <div className="breadcumbLeft">
                                    <h1>ჩემი პროფილი</h1>
                                </div>
                                <div className="breadcumbRight">
                                    <ul>
                                        <li><Link to="/">მთავარი</Link></li>
                                        <li><FontAwesomeIcon icon={faChevronRight} /></li>
                                        <li>პროფილი</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="contactSection">
                <Container>
                    <Row>

                        <Col xxl={4}>
                            <ProfileMenu />
                        </Col>

                        <Col xxl={8}>
                            <div className="profileCard">
                                <div className="profileEditBox">
                                    <h4 className="profileEditBoxTitle">პერსონალური ინფორმაცია</h4>
                                    <Row>
                                        <Col xxl={6}>
                                            <div className="profileForm">
                                                <label>თქვენი სახელი</label>
                                                <input value={fname ?? ''} onInput={(e)=>setFname(e.target.value)} type="text" name="fname"  placeholder="სახელი" />
                                            </div>
                                        </Col>
                                        <Col xxl={6}>
                                            <div className="profileForm">
                                                <label>გვარი</label>
                                                <input value={lname ?? ''} onInput={(e)=>setLname(e.target.value)} type="text" name="lname"  placeholder="გვარი" />
                                            </div>
                                        </Col>
                                        <Col xxl={6}>
                                            <div className="profileForm">
                                                <label>ელ.ფოსტა</label>
                                                <input value={email ?? ''} onInput={(e)=>setEmail(e.target.value)} type="text" name="email"  placeholder="ელ.ფოსტა" />
                                            </div>
                                        </Col>
                                        <Col xxl={6}>
                                            <div className="profileForm">
                                                <label>ტელეფონი</label>
                                                <input value={phone ?? ''} onInput={(e)=>setPhone(e.target.value)} type="text" name="phone"  placeholder="ტელეფონი" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <h4 className="profileEditBoxTitle">პაროლის ცვლილება</h4>
                                    <Row>
                                        <Col xxl={6}>
                                            <div className="profileForm">
                                                <label>პაროლი</label>
                                                <input value={password ?? ''} type="text" onInput={(e)=>setPassword(e.target.value)} name="password" placeholder="ახალი პაროლი" />
                                            </div>
                                        </Col>
                                        <Col xxl={6}>
                                            <div className="profileForm">
                                                <label>გაიმეორეთ პაროლი</label>
                                                <input value={repassword ?? ''} type="text" onInput={(e)=>setRePassword(e.target.value)} name="repass" placeholder="გაიმეორეთ პაროლი" />
                                            </div>
                                        </Col>
                                        <Col xxl={12}>
                                            <div className="saveProfile">

                                                { loading ? 
                                                    <Button className='saveButton'>
                                                        <BeatLoader
                                                            color='#fff'
                                                            size={10}
                                                            aria-label="Loading Spinner"
                                                            data-testid="loader"
                                                        />
                                                    </Button>
                                                : 
                                                    <Button className='saveButton' onClick={updateUser}>
                                                        პროფილის განახლება
                                                    </Button>
                                                }

                                                
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </div>
        
    )

}

export default Main;