import {useState, useEffect} from 'react'
import { useAppContext } from './../Context';
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

// import tags
import {
    Container,
    Image,
    Row,
    Col,
    Tab,
    Tabs,
    Button
} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {faGoogle} from '@fortawesome/free-brands-svg-icons';

// import resources

import LoginBg from '../../assets/image/login.jpg'
import registerBg from '../../assets/image/register.jpg'

// api

import {auth, register} from '../../api/Api'

function Auth(props) {

    const { login } = useAppContext();

    //login
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    const [googleUser, setGoogleUser] = useState(null);
    
    //register
    const [regFname, setRegFname] = useState('');
    const [regLname, setRegLname] = useState('');
    const [regEmail, setRegEmail] = useState('');
    const [regPassword, setRegPassword] = useState('');
    const [regRepeatPassword, setRegRepeatPassword] = useState('');
    const [response, setResponse] = useState(null);
    const [loading, setLoadgin] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false)

    //message
    const [message, setMessage] = useState(false);
    const [regMessage, setRegMessage] = useState(false);

    //validate login messages
    const [emailMessage, setEmailMessage] = useState(false);
    const [passwordMessage, setPasswordMessage] = useState(false);

    //validate register messages
    const [fnameMessage, setFnameMessage] = useState(false);
    const [lnameMessage, setLnameMessage] = useState(false);
    const [emailRegisterMessage, setEmailRegisterMessage] = useState(false);
    const [passwordRegisterMessage, setPasswordRegisterMessage] = useState(false);
    const [repeatPasswordMessage, setRepeatPassword] = useState(false);

    function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    useEffect(() => {
        if (user !== null) {
            if (user && user.isSuccess === true) {
                sessionStorage.setItem('token', user.token);
                login();
                props.setAuthModal(false);
            } else {
                setMessage('მომხმარებელი ან პაროლი არასწორია');
            }
        }

        setLoadgin(false);
        setGoogleLoading(false);

    }, [user]);

    const handleLogin = async () => {

        let isValid = true;

        if(email === "") {
            setEmailMessage("ჩაწერეთ ელ.ფოსტა");
            isValid = false;
        } else if(!validateEmail(email)) {
            setEmailMessage("ელ.ფოსტის ფორმატი არასწორია");
            isValid = false;
        } else {
            setEmailMessage(false);
        }

    
        if(password === "") {
            setPasswordMessage("ჩაწერეთ პაროლი");
            isValid = false;
        } else {
            setPasswordMessage(false);
        }

        if(isValid == false) {
            setMessage(false);
            return false;
        }

        setLoadgin(true);

        const data = {
            'email': email,
            'password': password
        };
    
        await auth(setUser, data);
    };


    useEffect(() => {
        if (response !== null) {
            if(response?.status == 'success') {
                alert(response.reason);
                props.setAuthModal(false);
            } else {
                setRegMessage(response.reason);
            }
        }
        setLoadgin(false);
    }, [response]);


    const handleRegister = async () => {
        
        let isValid = true;

        if(regFname === "") {
            setFnameMessage("ჩაწერეთ სახელი");
            isValid = false;
        } else {
            setFnameMessage(false);
        }

        if(regLname === "") {
            setLnameMessage("ჩაწერეთ გვარი");
            isValid = false;
        } else {
            setLnameMessage(false);
        }

        if(regEmail === "") {
            setEmailRegisterMessage("ჩაწერეთ ელ.ფოსტა");
            isValid = false;
        } else if(!validateEmail(regEmail)) {
            setEmailRegisterMessage("ელ.ფოსტის ფორმატი არასწორია");
            isValid = false;
        } else {
            setEmailRegisterMessage(false);
        }

    
        if(regPassword === "") {
            setPasswordRegisterMessage("ჩაწერეთ პაროლი");
            isValid = false;
        } else {
            setPasswordRegisterMessage(false);
        }

        if(regRepeatPassword === "") {
            setRepeatPassword("გაიმეორეთ პაროლი");
            isValid = false;
        } else {
            setRepeatPassword(false);
        }


        

        if(isValid == false) {
            setMessage(false);
            return false;
        }

        setLoadgin(true);

        if(regPassword == regRepeatPassword) {
            const data = {
                'fname': regFname,
                'lname': regLname,
                'email': regEmail,
                'password': regPassword
            }

            await register(setResponse, data);

        } else {
            setRegMessage('პაროლები არ ემთხვევა ერთმანეთს!')
        }

    };


    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
          try {
            const { access_token } = tokenResponse;
    
            const response = await axios.get(
              'https://www.googleapis.com/oauth2/v3/userinfo',
              { headers: { Authorization: `Bearer ${access_token}` } }
            );
    
            setGoogleUser(response.data);
            
          } catch (error) {
            console.error('Failed to fetch user info:', error);
          }
        },
        onError: error => {
          console.log('Login failed:', error);
        },
        scope: 'openid email profile', 
    });


    useEffect(()=> {
        
        if(googleUser !== null) {
            setGoogleLoading(true);
            const data = {
                'email': googleUser.email,
                'google_id': googleUser.sub,
                'given_name': googleUser.given_name,
                'family_name': googleUser.family_name
            };
        
            auth(setUser, data);
        }
    }, [googleUser]);
    

    return (
        <div className="authContainer">
            <div className="closeAuthModal"
                onClick={
                    () => props.setAuthModal(false)
            }>
                <FontAwesomeIcon icon={faTimes}/>
            </div>
            <Tabs defaultActiveKey="auth" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="auth" title="შესვლა">
                    <Row>
                        <Col xl={6}>
                            <Image className='w-100'
                                src={LoginBg}/>
                        </Col>
                        <Col xl={6}>
                            <h4 className='loginTitle'>შესვლა</h4>
                            { googleLoading ? 
                                
                                <div>
                                    <a className='googleAuth'>
                                        <FontAwesomeIcon icon={faGoogle}/>
                                        <span>
                                            <BeatLoader
                                                color='red'
                                                size={10}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        </span>
                                    </a>
                                </div>
                            : 
                                <div>
                                    <a className='googleAuth' onClick={() => googleLogin()}>
                                        <FontAwesomeIcon icon={faGoogle}/>
                                        <span>შესვლა Google-ით</span>
                                    </a>
                                </div>
                            }
                            
                            <div className='position-relative'>
                                <hr className='orAuth'/>
                            </div>
                            <div className="awd">
                                <div className="message badge text-danger mb-2 px-0">{ message }</div>
                                <div className='form-group mb-3'>
                                    <input className='form-control h52' type='text' placeholder='ელ.ფოსტა'
                                        value={email}
                                        onInput={
                                            (event) => setEmail(event.target.value)
                                        }/>
                                    
                                    { emailMessage ? <p className="errorMessage text-danger">{emailMessage}</p> : '' }
                                </div>
                                <div className='form-group'>
                                    <input className='form-control h52' type='password' placeholder='პაროლი'
                                        value={password}
                                        onInput={
                                            (event) => setPassword(event.target.value)
                                        }/>
                                    
                                    { passwordMessage ? <p className="errorMessage text-danger">{passwordMessage}</p> : '' }
                                </div>
                                <div className='authAdditionalOptions mt-5 mb-3'>
                                    <div className='position-relative'>
                                        <input type="checkbox" className="custom-control-input" id="exampleCheck1"/>
                                        <label className="custom-control-label" htmlFor="exampleCheck1">დამახსოვრება</label>
                                    </div>
                                    <a className="forgot" href="#">დაგავიწყდათ პაროლი?</a>
                                </div>
                                <div>
                                    
                                </div>
                                { loading ? 
                                    <Button variant='success' className='w-100 h52'>
                                        <BeatLoader
                                            color='#fff'
                                            size={10}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </Button>
                                : 
                                    <Button variant='success' className='w-100 h52' onClick={handleLogin}>
                                        შესვლა
                                    </Button>
                                }
                            </div>
                            <div className='suggestRegister'>
                                არ გაქვთ ექაუნთი? გაიარეთ <strong>რეგისტრაცია</strong>
                            </div>
                        </Col>
                    </Row>


                </Tab>
                <Tab eventKey="register" title="რეგისტრაცია">
                    <Row>
                        <Col xl={6}>
                            <Image className='w-100'
                                src={registerBg}/>
                        </Col>
                        <Col xl={6}>
                            <h4 className='loginTitle'>რეგისტრაცია</h4>
                            { googleLoading ? 
                                
                                <div>
                                    <a className='googleAuth'>
                                        <FontAwesomeIcon icon={faGoogle}/>
                                        <span>
                                            <BeatLoader
                                                color='red'
                                                size={10}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        </span>
                                    </a>
                                </div>
                            : 
                                <div>
                                    <a className='googleAuth' onClick={() => googleLogin()}>
                                        <FontAwesomeIcon icon={faGoogle}/>
                                        <span>შესვლა Google-ით</span>
                                    </a>
                                </div>
                            }
                            <div className='position-relative'>
                                <hr className='orAuth'/>
                            </div>


                            <div className="awd">
                                <div className="message badge text-danger mb-2 px-0">{ regMessage }</div>
                                <div className='form-group mb-3'>
                                    <input className='form-control h52' value={regFname} onInput={(event) => setRegFname(event.target.value)} type='text' placeholder='სახელი' />
                                    { fnameMessage ? <p className="errorMessage text-danger">{fnameMessage}</p> : '' }
                                </div>
                                <div className='form-group mb-3'>
                                    <input className='form-control h52' value={regLname} onInput={(event) => setRegLname(event.target.value)} type='text' placeholder='გვარი' />
                                    { lnameMessage ? <p className="errorMessage text-danger">{lnameMessage}</p> : '' }
                                </div>
                                <div className='form-group mb-3'>
                                    <input className='form-control h52' value={regEmail} onInput={(event) => setRegEmail(event.target.value)} type='text' placeholder='ელ.ფოსტა' />
                                    { emailRegisterMessage ? <p className="errorMessage text-danger">{emailRegisterMessage}</p> : '' }
                                </div>
                                <div className='form-group mb-3'>
                                    <input className='form-control h52' value={regPassword} onInput={(event) => setRegPassword(event.target.value)} type='password' placeholder='პაროლი' />
                                    { passwordRegisterMessage ? <p className="errorMessage text-danger">{passwordRegisterMessage}</p> : '' }
                                </div>
                                <div className='form-group mb-3'>
                                    <input className='form-control h52' value={regRepeatPassword} onInput={(event) => setRegRepeatPassword(event.target.value)} type='password' placeholder='გაიმეორეთ პაროლი' />
                                    { repeatPasswordMessage ? <p className="errorMessage text-danger">{repeatPasswordMessage}</p> : '' }
                                </div>
                                
                                <div className='authAdditionalOptions mt-4 mb-3'>
                                    <div className='position-relative'>
                                        <input type="checkbox" className="custom-control-input" id="exampleCheck2"/>
                                        <label className="custom-control-label" htmlFor="exampleCheck2">ვეთანხმები საიტის მოხმარების <Link to="/">წესებს და პირობებს</Link></label>
                                    </div>
                                </div>

                                    { loading ? 
                                        <Button variant='success' className='w-100 h52'>
                                            <BeatLoader
                                                color='#fff'
                                                size={10}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        </Button>
                                    : 
                                        <Button variant='success' className='w-100 h52' onClick={handleRegister}>
                                            რეგისტრაცია
                                        </Button>
                                    }

                            </div>
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </div>
    )

}

export default Auth;
