//import tags
import React, { useState, useEffect } from "react";
import { useAppContext } from "./../../components/Context";

//import tags
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper/modules";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

//import resources
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faLocationCrosshairs,
  faBed,
  faDoorOpen,
  faLayerGroup,
  faHashtag,
  faMobileScreen,
  faChevronRight,
  faWifi,
  faBuilding,
  faStreetView,
  faCalendar,
  faBuildingShield,
  faBuildingColumns,
  faStarHalf,
  faStar,
  faBuildingFlag,
  faBuildingNgo,
  faBuildingWheat,
  faBuildingUn,
  faCodeCommit,
  faCode,
  faListNumeric,
  faCheck,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart as faHeartRegular,
  faClone,
  faCalendarDays,
  faFlag,
  faCheckSquare,
} from "@fortawesome/free-regular-svg-icons";

import "swiper/css";
import "swiper/css/navigation";

import Propertyimage from "../../assets/image/property.jpg";

//api
import { get_property_details } from "../../api/Api";
import { faCodepen } from "@fortawesome/free-brands-svg-icons";

function Main() {
  const { getenumbykey } = useAppContext();

  const { id, slug } = useParams();

  const [property, setProperty] = useState(false);
  const [toggleView, setToggleView] = useState("0");
  const [isFavourite, setIsFavourite] = useState(false);
  const [message, setMessage] = useState("");
  const [currentCurrency, setCurrentCurrency] = useState("GEL");
  const [sortBy, setSortBy] = useState("0");
  const [showFullText, setShowFullText] = useState(false);

  const longText =
    "იყიდება გორში ჩეხური ტიპის აგურის ბინა. ბინის სრული ფართი არის 92 კვადრატული მეტრი აივნიანად. აივანზე არის ორი სათავსო. სამი საძინებელი, ერთი სველი წერტილი, გამოყოფილი მისაღები, ზალა და სამზარეულო. აქვს ორი სარდაფი, ორივეს მთლიანი ფართი 25 კვადრატული მეტრი.";

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const toggleViewHandler = () => {
    setToggleView(!toggleView);
  };

  const toggleFavourite = () => {
    setIsFavourite((prevState) => !prevState);
    setMessage(
      isFavourite
        ? "წაიშალა ფავორიტების სიიდან"
        : "ფავორიტებში წარმატებით დაემატა"
    );
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const toggleCurrency = (currency) => {
    setCurrentCurrency(currency);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const header = {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        };

        get_property_details(setProperty, header, slug);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (!property) {
      fetchData();
    }
  }, [property]);

  return (
    <div className="main">
      <section className="propertyDetailsSection">
        <Container>
          <Row>
            <Col xxl={12}>
              <div className="shortBreadcumb">
                <div className="shortBreadcumbLeft">
                  <h1>
                    {typeof property == "object" &&
                    property?.transaction_type != null
                      ? getenumbykey(parse(property.transaction_type))
                      : ""}{" "}
                    {typeof property == "object" &&
                    property?.property_type != null
                      ? getenumbykey(parse(property.property_type))
                      : ""}
                  </h1>
                </div>
                <div className="shortBreadcumbRight">
                  <ul>
                    <li>
                      <Link to="/">მთავარი</Link>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </li>
                    <li>განცხადებები</li>
                  </ul>
                </div>
              </div>
            </Col>

            {/* {id ?? id}
                        {slug ?? slug} */}

            <Col xxl={12}>
              {message && (
                <div className="addedToFafourites">
                  <h4>{message}</h4>
                </div>
              )}
              <div className="mainImageSlider">
                <Swiper
                  modules={[Navigation, A11y]}
                  spaceBetween={5}
                  slidesPerView={1}
                  navigation
                  scrollbar={{ draggable: true }}
                >
                  <SwiperSlide>
                    <div className="singleProductImage">
                      <Image src={Propertyimage} alt="Property image" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="singleProductImage">
                      <Image src={Propertyimage} alt="Property image" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="singleProductImage">
                      <Image src={Propertyimage} alt="Property image" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="singleProductImage">
                      <Image src={Propertyimage} alt="Property image" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="singleProductImage">
                      <Image src={Propertyimage} alt="Property image" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="singleProductImage">
                      <Image src={Propertyimage} alt="Property image" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="singleProductImage">
                      <Image src={Propertyimage} alt="Property image" />
                    </div>
                  </SwiperSlide>
                </Swiper>

                <div
                  className={`singleProductGridTag ${
                    property.statement_type === "exclusive"
                      ? "exclusiveAd"
                      : "reluxAd"
                  }`}
                >
                  <Link to="/">
                    <h4>
                      {typeof property == "object" &&
                      property?.statement_type != null
                        ? getenumbykey(parse(property.statement_type))
                        : ""}
                    </h4>
                  </Link>
                </div>

                <div
                  className="singleProductFavourite"
                  onClick={toggleFavourite}
                >
                  <FontAwesomeIcon
                    icon={isFavourite ? faHeart : faHeartRegular}
                  />
                </div>
              </div>
            </Col>

            <Col xxl={8}>
              <div className="propertyDetailsLeft">
                <div className="propertyDetailsTags">
                  <div className="singleTag">
                    {typeof property == "object" &&
                    property?.property_type != null
                      ? getenumbykey(parse(property.property_type))
                      : ""}
                  </div>
                  <div className="singleTag">
                    {typeof property == "object" && property?.city != null
                      ? getenumbykey(parse(property.city))
                      : ""}
                  </div>
                </div>

                <h2>
                  {typeof property == "object" &&
                  property?.transaction_type != null
                    ? getenumbykey(parse(property.transaction_type))
                    : ""}{" "}
                  {typeof property == "object" &&
                  property?.property_status != null
                    ? getenumbykey(parse(property.property_status))
                    : ""}{" "}
                  {typeof property == "object" &&
                  property?.property_type != null
                    ? getenumbykey(parse(property.property_type))
                    : ""}
                </h2>

                <div className="singlePropertyLocation">
                  <FontAwesomeIcon icon={faLocationCrosshairs} />
                  <p>
                    {typeof property == "object" && property?.region != null
                      ? getenumbykey(parse(property.region))
                      : ""}
                    ,{" "}
                    {typeof property == "object" &&
                    property?.municipality != null
                      ? getenumbykey(parse(property.municipality))
                      : ""}
                    ,{" "}
                    {typeof property == "object" && property?.village != null
                      ? property.village
                      : ""}
                    ,{" "}
                    {typeof property == "object" && property?.address != null
                      ? property.address
                      : ""}
                  </p>
                </div>

                <div className="propertyDetailsData">
                  <p>
                    <FontAwesomeIcon icon={faHashtag} />
                    <strong>
                      {typeof property == "object" && property?.id != null
                        ? property.id
                        : ""}
                    </strong>
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCalendarDays} />
                    <strong>
                      {typeof property === "object" && property?.created_at
                        ? new Date(property.created_at).toLocaleString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )
                        : ""}
                    </strong>
                  </p>
                </div>

                <div className="singlePropertyMiniBox">
                  {(() => {
                    switch (property?.property_type) {
                      case "land":
                        return (
                          <div className="singlePropertyMini" title="ფართი">
                            <FontAwesomeIcon icon={faClone} />:
                            <span>
                              ფართი
                              <strong>
                                {property?.area != null ? property.area : ""}{" "}
                              </strong>
                              მ<sup>2</sup>
                            </span>
                          </div>
                        );
                      case "apartment":
                        return (
                          <>
                            <div
                              className="singlePropertyMini"
                              title="საძინებელი"
                            >
                              <FontAwesomeIcon icon={faBed} />
                              <span>
                                საძინებელი
                                <strong>
                                  {property?.bedrooms != null
                                    ? property.bedrooms
                                    : ""}
                                </strong>
                              </span>
                            </div>
                            <div className="singlePropertyMini" title="ოთახი">
                              <FontAwesomeIcon icon={faDoorOpen} />
                              <span>
                                ოთახი
                                <strong>
                                  {property?.rooms != null
                                    ? property.rooms
                                    : ""}
                                </strong>
                              </span>
                            </div>
                            <div className="singlePropertyMini" title="სართული">
                              <FontAwesomeIcon icon={faLayerGroup} />
                              <span>
                                სართული
                                <strong>
                                  {property?.floor != null
                                    ? property.floor
                                    : ""}
                                </strong>
                              </span>
                            </div>
                            <div className="singlePropertyMini" title="ფართი">
                              <FontAwesomeIcon icon={faClone} />
                              <span>
                                ფართი
                                <strong>
                                  {property?.area != null ? property.area : ""}{" "}
                                </strong>
                                მ<sup>2</sup>
                              </span>
                            </div>
                          </>
                        );
                      case "business":
                        return (
                          <>
                            <div className="singlePropertyMini" title="ფართი">
                              <FontAwesomeIcon icon={faClone} />
                              <span>
                                ფართი
                                <strong>
                                  {property?.area != null ? property.area : ""}{" "}
                                </strong>
                                მ<sup>2</sup>
                              </span>
                            </div>
                            <div className="singlePropertyMini" title="ოთახი">
                              <FontAwesomeIcon icon={faDoorOpen} />
                              <span>
                                ოთახი
                                <strong>
                                  {property?.rooms != null
                                    ? property.rooms
                                    : ""}
                                </strong>
                              </span>
                            </div>
                          </>
                        );
                      default:
                        return null;
                    }
                  })()}
                </div>

                <div className="propertyCharacteristics">
                  <h3>მახასიათებლები</h3>

                  <div className="propertyCharacteristicsInner">
                    <Row>
                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faBuilding} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>პროექტი</p>
                            <h5>
                              {typeof property == "object" &&
                              property?.project != null
                                ? getenumbykey(parse(property.project))
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>კლასი</p>
                            <h5>
                              {typeof property == "object" &&
                              property?.class != null
                                ? getenumbykey(parse(property.class))
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faBuildingShield} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>მდგომარეობა</p>
                            <h5>
                              {typeof property == "object" &&
                              property?.condition != null
                                ? getenumbykey(parse(property.condition))
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faBuildingColumns} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>სამშენებლო კომპანია</p>
                            <h5>
                              {typeof property == "object" &&
                              property?.building_company != null
                                ? property.building_company
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faCalendarDays} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>აშენების თარიღი</p>
                            <h5>
                              {typeof property == "object" &&
                              property?.build_date != null
                                ? property.build_date
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faCalendarDays} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>დაწყება-დასრულების დრო</p>
                            <h5>
                              {typeof property == "object" &&
                              property?.start_date != null
                                ? property.start_date
                                : ""}{" "}
                              -{" "}
                              {typeof property == "object" &&
                              property?.end_date != null
                                ? property.end_date
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faHashtag} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>საკადასტრო კოდი 1</p>
                            <h5>15231 512351 235</h5>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faHashtag} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>საკადასტრო კოდი 2</p>
                            <h5>152315 1235124 42</h5>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="propertyDescription">
                  <h3>აღწერა</h3>
                  <p>
                    {showFullText ? longText : longText.slice(0, 142)}
                    {longText.length > 150 && (
                      <div className="showMoreText" onClick={toggleText}>
                        {showFullText ? "დამალვა" : "მეტის ნახვა"}
                      </div>
                    )}
                  </p>
                </div>

                <div className="propertyDetails">
                  <div className="propertyCharacteristics">
                    <h3>დეტალები</h3>
                    <div className="propertyCharacteristicsInner">
                      <Row>
                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>სრული ფართი</p>
                              <h5>
                                124 მ<sup>2</sup>
                              </h5>
                            </div>
                          </div>
                        </Col>

                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>ოთახების რაოდენობა</p>
                              <h5>3</h5>
                            </div>
                          </div>
                        </Col>

                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>საძინებლების რაოდენობა</p>
                              <h5>2</h5>
                            </div>
                          </div>
                        </Col>

                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>მასტერ საძინებლების რ.ბა</p>
                              <h5>2</h5>
                            </div>
                          </div>
                        </Col>

                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>სართული</p>
                              <h5>4</h5>
                            </div>
                          </div>
                        </Col>

                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>სართული სულ</p>
                              <h5>12</h5>
                            </div>
                          </div>
                        </Col>

                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>სველი წერტილების რ.ბა</p>
                              <h5>2</h5>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>პარამეტრები</h3>

                          <div className="propertyDetailsPropBox">
                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>მისაღები</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                                <p>
                                  რაოდენობა: <strong>2</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>სტუდიო</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>სამზარეულო</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                                <p>
                                  რაოდენობა: <strong>2</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>ღია აივანი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                                <p>
                                  რაოდენობა: <strong>2</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>დახურული აივანი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                                <p>
                                  რაოდენობა: <strong>2</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>ფრანგული აივანი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                                <p>
                                  რაოდენობა: <strong>2</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>ვერანდა/ტერასა</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                                <p>
                                  რაოდენობა: <strong>2</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>საგარდერობო ოთახი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                                <p>
                                  რაოდენობა: <strong>2</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>სათავსო</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                                <p>
                                  რაოდენობა: <strong>2</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>სხვენი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>დუპლექსი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>ტრიპლექსი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>საზაფხულო სამზარეულო</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>სარდაფი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                                <p>
                                  რაოდენობა: <strong>2</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>ლოჯი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>ჰოლი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>დაშენება</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>მიშენება</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>საერთო კორიდორი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>ანტრესორი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>ზამთრის ბაღი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>ავტოფარეხი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>ეზოს პარკინგი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>მიწისქვეშა პარკინგი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>პარკინგის ადგილი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>ფასიანი პარკინგი</h5>
                                <h6>
                                  ფართი კვ.მ:{" "}
                                  <strong>
                                    124 მ<sup>2</sup>
                                  </strong>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>პარამეტრები</h3>

                          <div className="propertyDetailsPropBox">
                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>სამგზავრო ლიფტი</h5>
                                <p>
                                  რაოდენობა: <strong>2</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>სატვირთო ლიფტი</h5>
                                <p>
                                  რაოდენობა: <strong>2</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>პერსონალური ლიფტი</h5>
                                <p>
                                  <strong>კი</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>ღია აუზი</h5>
                                <p>
                                  <strong>კი</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>დახურული აუზი</h5>
                                <p>
                                  <strong>კი</strong>
                                </p>
                              </div>
                            </div>

                            <div className="mainParamBox">
                              <div className="paramIcon">
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </div>
                              <div className="paramText">
                                <h5>ბუხარი</h5>
                                <p>
                                  რაოდენობა: <strong>1</strong>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>ცხელი წყალი</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ცენტრალური გათბობის სისტემა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>გაზის გამაცხელებელი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ავზი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>დენის გამაცხელებელი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ბუნებრივი ცხელი წყალი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მზის გამათბობელი</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>გათბობა</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ცენტრალური გათბობა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>გაზის გამათბობელი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>იატაკის გათბობა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>დენის გამათბობელი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ჩილერი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მზის გამათბობელი</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>ზოგადი კეთილმოწყობა</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ბუნებრივი აირი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>გენერატორი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ინტერნეტი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ტელევიზია</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={6}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სიგნალიზაცია</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>ავეჯი</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მისაღები ოთახის ავეჯი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>რბილი ავეჯი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სამზარეულოს ავეჯი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საძინებლის ავეჯი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საზაფხულო ავეჯი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საოფისე ავეჯი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საბავშვო ავეჯი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ნაწილობრივ ავეჯი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ავეჯის გარეშე</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>ტექნიკა</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ტელევიზორი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მაცივარი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მიკროტალღური ღუმელი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ჭურჭლის სარეცხი მანქანა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სარეცხი მანქანა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>კონდინციონერი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მუსიკალური ცენტრი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>გამწოვი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>გაზქურა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მტვერსასრუტი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>უთო</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>კინოთეატრი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საშრობი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ყავის აპარატი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ელექტრო ჩაიდანი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ტოსტერი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>წვენსაწური</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მიქსერი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ბლენდერი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ხორცის საკეპი მანქანა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>კომპიუტერი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>პრინტერი / სკანერი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სეიფი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სპორტული ტრენაჟორები</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>დაცვის სერვისი</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სიგნალიზაცია</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ცოცხალი დაცვა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ვიდეო კამერები</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სადარბაზოს დაშვების სისტემა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ჩაკეტილი ეზოს დაშვების სისტემა</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>ჭკვიანი სახლი</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მართვის პანელები</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>უსაფრთხოების სისტემები</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ჭკვიანი კამერა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ფარდა ჟალუზი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>განათების ავტომატიზირება</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ჭკვიანი საკეტი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საყოფაცხოვრებო ტექნიკის მართვა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>აუდიო სისტემა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ჭკვიანი დომოფონი</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>დამატებითი უპირატესობები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მოწესრიგებული ინფრასტრუქტურა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>პანორამული ხედი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ხედი ქალაქზე</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ხედი ზღვაზე</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ხედი მთაზე</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ხედი მდინარეზე</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ხედი ტბაზე</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ხედი ეზოში</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ნათელი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ვიტრაჟული კარ-ფანჯარა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>გამწვანება</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საბავშვო სივრცე</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სპორტული მოედანი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>კონსიაჟი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საერთო სამუშაო სივრცე</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ბიზნეს ლობი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>კაფეტერია</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>რესტორანი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საკონფერენციო დარბაზი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ივენთ ჰოლი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>კომერციული ფართი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საცხოივრებელი ლობი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>უფასო Wifi</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სამრეცხაო</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სპა ცენტრი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საუნა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ფიტნეს დარბაზი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>კორტები</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>გოლფის მოედანი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მულტიფუნქციური</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ენერგო ეფექტური</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>თანამედროვე არქიტექტურა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>შემინული აივნები</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ჯაკუზი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>დუშ კაბინა + ვანა</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>დამატებითი უსაფრთხოება</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საევაკუაციო კიბე</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ხანძრის საწინააღმდეგო სისტემა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>CO დეტექტორი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>კვამლის დეტექტორი</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>ობიექტთან ახლოს</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ქალაქის ცენტრი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ავტობუსის გაჩერება</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სავაჭრო ცენტრი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სკოლა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საბავშვო ბაღი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მეტროსადგური</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>კინოთეატრი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სკვერი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მუზეუმი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>რესტორანი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>სუპერმარკეტი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>აფთიაქი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>საავადმყოფო</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>რკინიგზის სადგური</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>აეროპორტი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ზღვა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მდინარე</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ტბა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ნაკრძალი</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>დაშვებულია</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>წვეულების გამართვა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>შინაური ცხოველები</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მოწევა</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* Hidden details start */}

                  {/* კერძო სახლი / თაუნჰაუსი */}
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>კერძო სახლის / თაუნ ჰაუსის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ეზო</h5>
                                    <h6>
                                      ფართი კვ.მ:{" "}
                                      <strong>
                                        65 მ<sup>2</sup>
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ღობე</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ვაზი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ხეხილი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ციტრუსი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ფოთლოვანი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>წიწვოვანი</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className="propertyDetailsInner">
                            <h4>გზა</h4>

                            <Row>
                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ასფალტიანი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>მოხრეშილი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>გრუნტი</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className="propertyDetailsInner">
                            <h4>აშენების მასალა</h4>

                            <Row>
                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ბლოკი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>აგური</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ქვა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ხე</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>პემზის ბლოკი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>რკინა-ბეტონი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>კომბინირებული</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className="propertyDetailsInner">
                            <h4>პროექტი</h4>
                            <Row>
                              <Col xxl={12}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ინდივიადუალური</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>პროექტი</h5>
                                    <p>
                                      სამშენებლო კომპანიის დასახელება:
                                      <span>მეტრა დეველოპმენტი</span>
                                    </p>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>არასტანდარტული</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className="propertyDetailsInner">
                            <h4>გადახურვა</h4>

                            <Row>
                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ბრტყელი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>კონუსებური</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>თუნუქის გადახურვა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>ტოლით დაგახურვა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainListParamBox">
                                  <div className="paramListIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramListText">
                                    <h5>კრამიტის სახურავი</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* კომერციული / საოფისე ფართი */}
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>კომერციული / საოფისე ფართის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-32">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ფართის ტიპი</h5>
                                    <h6>საწარმოო ფართი</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ვიტრინა</h5>
                                    <h6>
                                      რაოდენობა: <strong>2</strong>
                                    </h6>
                                    <p>
                                      სიგრძე (სმ): <strong>2</strong>
                                    </p>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ფასადი</h5>
                                    <h6>
                                      სიგრძე (სმ): <strong>2</strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>დარბაზი</h5>
                                    <h6>
                                      რაოდენობა: <strong>2</strong>
                                    </h6>
                                    <p>
                                      ფართი კვ.მ::{" "}
                                      <strong>
                                        124 მ<sup>2</sup>
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* სასტუმრო */}
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>სასტუმროს დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>სასტუმროს ტიპი</h5>
                                    <h6>ჰოსტელი</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ვარსკვლავების რაოდენობა</h5>
                                    <h6>4 ვარსკვლავი</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ნომრების რაოდენობა</h5>
                                    <h6>25</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ლუქსების რაოდენობა</h5>
                                    <h6>12</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>კვება</h5>
                                    <h6>ერთჯერადი</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>კვება</h5>
                                    <h6>
                                      აქტივობები რაც გააჩნია სასტუმროს
                                      ჩამოთვლილი იქნება აქ...
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* ბიზნესცენტრი */}
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>ბიზნესცენტრის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ბიზნესცენტრის კლასი</h5>
                                    <h6>AA კლასი</h6>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* მიწა */}
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>მიწის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>მიწის ტიპი</h5>
                                    <h6>საინვესტიციო მშენებლობისთვის</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ფუნქციური ზონა</h5>
                                    <h6>სზ (1-6)</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-32">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>სას. სამეურნეო მიწის ტიპი</h5>
                                    <h6>საბაღე</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>შენობა ნაგებობა</h5>
                                    <h6>
                                      სრული ფართი კვ.მ:{" "}
                                      <strong>
                                        124 მ<sup>2</sup>
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>შეთანხმებული პროექტი</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>მშენებლობის ნებართვა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ფასადი</h5>
                                    <h6>
                                      სიგრძე (სმ): <strong>2</strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <div className="propertyDetailsInner">
                              <h4>კომუნიკაციები</h4>

                              <Row>
                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>გზა</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>დენი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>წყალი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>გაზი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>კოლექტორი-კანალიზაცია</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>ჭა</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>სერვიტუტი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>პირველი ზოლი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>მეორე ზოლი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>მესამე ზოლი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>ღობე</h5>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* ბიზნეს ინვესტიცა */}
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>ბიზნეს ინვესტიციის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ბიზნეს ინვესტიციის კატეგორია</h5>
                                    <h6>ბენზინგასამართი სადგური</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>სხვა ინდუსტრია</h5>
                                    <h6>სხვა ინდუსტრიის დასახელება</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>მესაკუთრე</h5>
                                    <h6>მცირე მეწარმე</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>საიდენტიფიკაციო კოდი</h5>
                                    <h6>123456789</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>შენობა ნაგებობა</h5>
                                    <h6>
                                      რაოდენობა: <strong>3</strong>
                                    </h6>
                                    <h6>
                                      სრული ფართი კვ.მ:{" "}
                                      <strong>
                                        124 მ<sup>2</sup>
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>საკუთრების ტიპი</h5>
                                    <h6>იურიდიული პირი</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>მიწის ფართი</h5>
                                    <h6>
                                      <strong>
                                        124 მ<sup>2</sup>
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>თანამშრომლების რაოდენობა</h5>
                                    <h6>
                                      <strong>132</strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-32">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>აქტივები</h5>
                                    <h6>
                                      <strong>კი</strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainParamBox mb-32">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>მძიმე ტექნიკა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={4}>
                                <div className="mainParamBox mb-32">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>სპეც ტექნიკა</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>წლიური დარიცხვა</h5>
                                    <h6>
                                      GEL: <strong>245 000</strong>
                                    </h6>
                                    <h6>
                                      USD: <strong>120 000</strong>
                                    </h6>
                                    <h6>
                                      RUB: <strong>110 000</strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>წლიური წმინდა მოგება</h5>
                                    <h6>
                                      GEL: <strong>245 000</strong>
                                    </h6>
                                    <h6>
                                      USD: <strong>120 000</strong>
                                    </h6>
                                    <h6>
                                      RUB: <strong>110 000</strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* ფერმა */}
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>ფერმის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ფერმის ტიპი</h5>
                                    <h6>მეფრინველეობა</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>სხვა ფერმის ტიპი</h5>
                                    <h6>სხვა ფერმის ტიპის დასახელება</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>მიწის ტიპი</h5>
                                    <h6>არასასოფლო სამეურნეო</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>სას. სამეურნეო მიწის ტიპი</h5>
                                    <h6>საკარმიდამო</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>შენობა ნაგებობა (ოფისი)</h5>
                                    <h6>
                                      რაოდენობა: <strong>2</strong>
                                    </h6>
                                    <h6>
                                      სრული ფართი კვ.მ:{" "}
                                      <strong>
                                        84 მ<sup>2</sup>
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>შენობა ნაგებობა (ოფისი)</h5>
                                    <h6>
                                      რაოდენობა: <strong>2</strong>
                                    </h6>
                                    <h6>
                                      სრული ფართი კვ.მ:{" "}
                                      <strong>
                                        84 მ<sup>2</sup>
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                              
                            </Row>

                            <div className="propertyDetailsInner">
                              <h4>კომუნიკაციები</h4>

                              <Row>
                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>გზა</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>დენი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>წყალი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>გაზი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>კოლექტორი-კანალიზაცია</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>ჭა</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>სერვიტუტი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>პირველი ზოლი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>მეორე ზოლი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>მესამე ზოლი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>ღობე</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>საკუთარი ტრანსფორმატორი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>საკუთარი გენერატორი</h5>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            <div className="propertyDetailsInner">
                              <h4>დამატებითი დეტალები</h4>

                              <Row>
                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>სატუმბი სისტემა</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>ჭაბურღილი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>წვეთოვანი სარწყავი სისტემა</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>რკინიგზის ჩიხი</h5>
                                    </div>
                                  </div>
                                </Col>

                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>კაპიტალური ღობე</h5>
                                    </div>
                                  </div>
                                </Col>

                              </Row>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                    {/* იყიდება განვადებით */}
                    <div className="detailsCardBox">
                        <Row>
                            <Col xxl={12}>
                                <div className="propertyDetailsProp">
                                <h3>განვადებით გაყიდვის დეტალები</h3>

                                <div className="propertyDetailsPropBox">
                                    <Row>

                                        <Col xxl={12}>
                                            <div className="mainParamBox mb-12">
                                            <div className="paramIcon">
                                                <FontAwesomeIcon icon={faBuilding} />
                                            </div>
                                            <div className="paramText">
                                                <h5>პირველადი შესატანი თანხა</h5>
                                                <h6><strong>12400 <sub>ლარი</sub></strong></h6>
                                            </div>
                                            </div>
                                        </Col>

                                        <Col xxl={12}>
                                            <div className="mainParamBox mb-12">
                                            <div className="paramIcon">
                                                <FontAwesomeIcon icon={faBuilding} />
                                            </div>
                                            <div className="paramText">
                                                <h5>ყოველთვიური შესატანი თანხა</h5>
                                                <h6><strong>1200 <sub>ლარი</sub></strong></h6>
                                            </div>
                                            </div>
                                        </Col>

                                        <Col xxl={12}>
                                            <div className="mainParamBox mb-12">
                                            <div className="paramIcon">
                                                <FontAwesomeIcon icon={faBuilding} />
                                            </div>
                                            <div className="paramText">
                                                <h5>საბოლოო თანხა ჯამში</h5>
                                                <h6><strong>48000 <sub>ლარი</sub></strong></h6>
                                            </div>
                                            </div>
                                        </Col>

                                        <Col xxl={12}>
                                            <div className="mainParamBox mb-12">
                                            <div className="paramIcon">
                                                <FontAwesomeIcon icon={faBuilding} />
                                            </div>
                                            <div className="paramText">
                                                <h5>მოქმედების ვადა</h5>
                                                <h6><strong>18 <sub>თვე</sub></strong></h6>
                                            </div>
                                            </div>
                                        </Col>
                                        
                                    </Row>
                                </div>
                                </div>
                            </Col>
                        </Row>
                    </div>


                    {/* ქირავდება დღიურად */}
                    <div className="detailsCardBox">
                        <Row>
                            <Col xxl={12}>
                                <div className="propertyDetailsProp">
                                <h3>დღიურად გაქირავების დეტალები</h3>

                                <div className="propertyDetailsPropBox">
                                    <Row>

                                        <Col xxl={12}>
                                            <div className="mainParamBox mb-12">
                                            <div className="paramIcon">
                                                <FontAwesomeIcon icon={faBuilding} />
                                            </div>
                                            <div className="paramText">
                                                <h5>გაქირავების დაწყების დრო</h5>
                                                <h6><strong>10/04/2024</strong></h6>
                                            </div>
                                            </div>
                                        </Col>

                                        <Col xxl={12}>
                                            <div className="mainParamBox mb-12">
                                            <div className="paramIcon">
                                                <FontAwesomeIcon icon={faBuilding} />
                                            </div>
                                            <div className="paramText">
                                                <h5>გაქირავების დასრულების დრო</h5>
                                                <h6><strong>10/26/2025</strong></h6>
                                            </div>
                                            </div>
                                        </Col>
                                        
                                    </Row>
                                </div>
                                </div>
                            </Col>
                        </Row>
                    </div>


                    {/* იყიდება აუქციონით */}
                    <div className="detailsCardBox">
                        <Row>
                            <Col xxl={12}>
                                <div className="propertyDetailsProp">
                                <h3>აუქციონით გაყიდვის დეტალები</h3>

                                <div className="propertyDetailsPropBox">
                                    <Row>

                                        <Col xxl={12}>
                                            <div className="mainParamBox mb-12">
                                            <div className="paramIcon">
                                                <FontAwesomeIcon icon={faBuilding} />
                                            </div>
                                            <div className="paramText">
                                                <h5>აუქციონის ბოლო ვადა</h5>
                                                <h6><strong>10/04/2024</strong></h6>
                                            </div>
                                            </div>
                                        </Col>

                                        <Col xxl={12}>
                                            <div className="mainParamBox mb-12">
                                            <div className="paramIcon">
                                                <FontAwesomeIcon icon={faBuilding} />
                                            </div>
                                            <div className="paramText">
                                                <h5>ყოველთვიური შესატანი თანხა</h5>
                                                <h6><strong>1 400 <sub>ლარი</sub></strong></h6>
                                            </div>
                                            </div>
                                        </Col>

                                        <Col xxl={12}>
                                            <div className="mainParamBox mb-12">
                                            <div className="paramIcon">
                                                <FontAwesomeIcon icon={faBuilding} />
                                            </div>
                                            <div className="paramText">
                                                <h5>საბოლოო თანხა ჯამში</h5>
                                                <h6><strong>120 000 <sub>ლარი</sub></strong></h6>
                                            </div>
                                            </div>
                                        </Col>
                                        
                                    </Row>
                                </div>
                                </div>
                            </Col>
                        </Row>
                    </div>


                  {/* Hidden details end */}
                </div>
              </div>
            </Col>

            <Col xxl={4}>
              <div className="propertyDetailsRight">
                <div className="singleProductPrice">
                  {["GEL", "USD", "EUR"].includes(currentCurrency) && (
                    <>
                      <h4>
                        {property?.[`price_${currentCurrency.toLowerCase()}`] !=
                        null
                          ? property?.[`price_${currentCurrency.toLowerCase()}`]
                          : ""}
                        <span>{currentCurrency}</span>
                      </h4>
                      {console.log(currentCurrency.toLowerCase())}
                    </>
                  )}
                  <div className="priceBoxDetails">
                    <div
                      className={`singlePropertyPrice ${
                        currentCurrency === "GEL" ? "active" : ""
                      }`}
                      onClick={() => toggleCurrency("GEL")}
                    >
                      <p>GEL</p>
                    </div>
                    <div
                      className={`singlePropertyPrice ${
                        currentCurrency === "USD" ? "active" : ""
                      }`}
                      onClick={() => toggleCurrency("USD")}
                    >
                      <p>USD</p>
                    </div>
                    <div
                      className={`singlePropertyPrice ${
                        currentCurrency === "EUR" ? "active" : ""
                      }`}
                      onClick={() => toggleCurrency("EUR")}
                    >
                      <p>EUR</p>
                    </div>
                  </div>
                </div>
                <div className="oneSquarePrice">
                  <p>
                    1 მ<sup>2</sup> <span>1.250 {currentCurrency}</span>
                  </p>
                </div>

                <div className="oneSquareArea">
                  <p>
                    ფართი:{" "}
                    <span>
                      1250 მ<sup>2</sup>
                    </span>
                  </p>
                </div>

                <div className="adAuthor">
                  <Link to="/">
                    <div className="adAuthorImg">
                      <h4>R</h4>
                    </div>
                  </Link>
                  <div className="adAuthorText">
                    <Link to="/">
                      <h5>Relux</h5>
                    </Link>
                    <p>დაგვიკავშირდით</p>
                  </div>
                </div>

                <a href="tel:+995 555 123 456">
                  <div className="adAuthorPhone">
                    <FontAwesomeIcon icon={faMobileScreen} />
                    <div className="phoneNumber">+995 555 123 456</div>
                  </div>
                </a>
              </div>
            </Col>

            <Col xxl={12}>
              <div className="relatedAds">
                <h6>მსგავსი განცხადებები</h6>
                <Swiper
                  modules={[Navigation, A11y]}
                  spaceBetween={5}
                  slidesPerView={4}
                  navigation
                  scrollbar={{ draggable: true }}
                >
                  <SwiperSlide>
                    <div className="singleProductGridBox">
                      {message && (
                        <div className="addedToFafourites">
                          <h4>{message}</h4>
                        </div>
                      )}
                      <div className="singleProductGridBoxImageBox">
                        <Link to="/property/details/1">
                          <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={20}
                            slidesPerView={1}
                            navigation
                            scrollbar={{ draggable: true }}
                          >
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                          </Swiper>
                        </Link>
                        <div className="singleProductGridTag">
                          <Link to="/">
                            <h4>ექსკლუზიური</h4>
                          </Link>
                        </div>
                        <div
                          className="singleProductGridFavourite"
                          onClick={toggleFavourite}
                        >
                          <FontAwesomeIcon
                            icon={isFavourite ? faHeart : faHeartRegular}
                          />
                        </div>
                        <div className="singleProductGridPrice">
                          <h4>
                            180000 <span>{currentCurrency}</span>
                          </h4>
                          <div className="priceBoxGrid">
                            <div
                              className={`singlePrice ${
                                currentCurrency === "GEL" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("GEL")}
                            >
                              <p>GEL</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "USD" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("USD")}
                            >
                              <p>USD</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "EUR" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("EUR")}
                            >
                              <p>EUR</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="singleProductGridBoxContant">
                        <div className="propertyGridTags">
                          <div className="singleTag">ბინა</div>
                          <div className="singleTag">თბილისი</div>
                        </div>
                        <Link to="/property/details/1">
                          <h1>
                            იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს
                          </h1>
                        </Link>
                        <div className="singlePropertyGridLocation">
                          <FontAwesomeIcon icon={faLocationCrosshairs} />
                          <p>ა.წერეთლის გამზირი #137, 0119</p>
                        </div>
                        <div className="singlePropertyListMiniBox">
                          <div
                            className="singlePropertyGridMini"
                            title="საძინებელი"
                          >
                            <FontAwesomeIcon icon={faBed} />: <span>4</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ოთახი">
                            <FontAwesomeIcon icon={faDoorOpen} />:{" "}
                            <span>3</span>
                          </div>
                          <div
                            className="singlePropertyGridMini"
                            title="სართული"
                          >
                            <FontAwesomeIcon icon={faLayerGroup} />:{" "}
                            <span>12/14</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ფართი">
                            <FontAwesomeIcon icon={faClone} />:{" "}
                            <span>
                              120 მ<sup>2</sup>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="singleProductGridBox">
                      {message && (
                        <div className="addedToFafourites">
                          <h4>{message}</h4>
                        </div>
                      )}
                      <div className="singleProductGridBoxImageBox">
                        <Link to="/property/details/1">
                          <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={5}
                            slidesPerView={1}
                            navigation
                            scrollbar={{ draggable: true }}
                          >
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                          </Swiper>
                        </Link>
                        <div className="singleProductGridTag">
                          <Link to="/">
                            <h4>ექსკლუზიური</h4>
                          </Link>
                        </div>
                        <div
                          className="singleProductGridFavourite"
                          onClick={toggleFavourite}
                        >
                          <FontAwesomeIcon
                            icon={isFavourite ? faHeart : faHeartRegular}
                          />
                        </div>
                        <div className="singleProductGridPrice">
                          <h4>
                            180000 <span>{currentCurrency}</span>
                          </h4>
                          <div className="priceBoxGrid">
                            <div
                              className={`singlePrice ${
                                currentCurrency === "GEL" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("GEL")}
                            >
                              <p>GEL</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "USD" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("USD")}
                            >
                              <p>USD</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "EUR" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("EUR")}
                            >
                              <p>EUR</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="singleProductGridBoxContant">
                        <div className="propertyGridTags">
                          <div className="singleTag">ბინა</div>
                          <div className="singleTag">თბილისი</div>
                        </div>
                        <Link to="/property/details/1">
                          <h1>
                            იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს
                          </h1>
                        </Link>
                        <div className="singlePropertyGridLocation">
                          <FontAwesomeIcon icon={faLocationCrosshairs} />
                          <p>ა.წერეთლის გამზირი #137, 0119</p>
                        </div>
                        <div className="singlePropertyListMiniBox">
                          <div
                            className="singlePropertyGridMini"
                            title="საძინებელი"
                          >
                            <FontAwesomeIcon icon={faBed} />: <span>4</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ოთახი">
                            <FontAwesomeIcon icon={faDoorOpen} />:{" "}
                            <span>3</span>
                          </div>
                          <div
                            className="singlePropertyGridMini"
                            title="სართული"
                          >
                            <FontAwesomeIcon icon={faLayerGroup} />:{" "}
                            <span>12/14</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ფართი">
                            <FontAwesomeIcon icon={faClone} />:{" "}
                            <span>
                              120 მ<sup>2</sup>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="singleProductGridBox">
                      {message && (
                        <div className="addedToFafourites">
                          <h4>{message}</h4>
                        </div>
                      )}
                      <div className="singleProductGridBoxImageBox">
                        <Link to="/property/details/1">
                          <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={5}
                            slidesPerView={1}
                            navigation
                            scrollbar={{ draggable: true }}
                          >
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                          </Swiper>
                        </Link>
                        <div className="singleProductGridTag">
                          <Link to="/">
                            <h4>ექსკლუზიური</h4>
                          </Link>
                        </div>
                        <div
                          className="singleProductGridFavourite"
                          onClick={toggleFavourite}
                        >
                          <FontAwesomeIcon
                            icon={isFavourite ? faHeart : faHeartRegular}
                          />
                        </div>
                        <div className="singleProductGridPrice">
                          <h4>
                            180000 <span>{currentCurrency}</span>
                          </h4>
                          <div className="priceBoxGrid">
                            <div
                              className={`singlePrice ${
                                currentCurrency === "GEL" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("GEL")}
                            >
                              <p>GEL</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "USD" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("USD")}
                            >
                              <p>USD</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "EUR" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("EUR")}
                            >
                              <p>EUR</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="singleProductGridBoxContant">
                        <div className="propertyGridTags">
                          <div className="singleTag">ბინა</div>
                          <div className="singleTag">თბილისი</div>
                        </div>
                        <Link to="/property/details/1">
                          <h1>
                            იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს
                          </h1>
                        </Link>
                        <div className="singlePropertyGridLocation">
                          <FontAwesomeIcon icon={faLocationCrosshairs} />
                          <p>ა.წერეთლის გამზირი #137, 0119</p>
                        </div>
                        <div className="singlePropertyListMiniBox">
                          <div
                            className="singlePropertyGridMini"
                            title="საძინებელი"
                          >
                            <FontAwesomeIcon icon={faBed} />: <span>4</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ოთახი">
                            <FontAwesomeIcon icon={faDoorOpen} />:{" "}
                            <span>3</span>
                          </div>
                          <div
                            className="singlePropertyGridMini"
                            title="სართული"
                          >
                            <FontAwesomeIcon icon={faLayerGroup} />:{" "}
                            <span>12/14</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ფართი">
                            <FontAwesomeIcon icon={faClone} />:{" "}
                            <span>
                              120 მ<sup>2</sup>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="singleProductGridBox">
                      {message && (
                        <div className="addedToFafourites">
                          <h4>{message}</h4>
                        </div>
                      )}
                      <div className="singleProductGridBoxImageBox">
                        <Link to="/property/details/1">
                          <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={5}
                            slidesPerView={1}
                            navigation
                            scrollbar={{ draggable: true }}
                          >
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                          </Swiper>
                        </Link>
                        <div className="singleProductGridTag">
                          <Link to="/">
                            <h4>ექსკლუზიური</h4>
                          </Link>
                        </div>
                        <div
                          className="singleProductGridFavourite"
                          onClick={toggleFavourite}
                        >
                          <FontAwesomeIcon
                            icon={isFavourite ? faHeart : faHeartRegular}
                          />
                        </div>
                        <div className="singleProductGridPrice">
                          <h4>
                            180000 <span>{currentCurrency}</span>
                          </h4>
                          <div className="priceBoxGrid">
                            <div
                              className={`singlePrice ${
                                currentCurrency === "GEL" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("GEL")}
                            >
                              <p>GEL</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "USD" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("USD")}
                            >
                              <p>USD</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "EUR" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("EUR")}
                            >
                              <p>EUR</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="singleProductGridBoxContant">
                        <div className="propertyGridTags">
                          <div className="singleTag">ბინა</div>
                          <div className="singleTag">თბილისი</div>
                        </div>
                        <Link to="/property/details/1">
                          <h1>
                            იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს
                          </h1>
                        </Link>
                        <div className="singlePropertyGridLocation">
                          <FontAwesomeIcon icon={faLocationCrosshairs} />
                          <p>ა.წერეთლის გამზირი #137, 0119</p>
                        </div>
                        <div className="singlePropertyListMiniBox">
                          <div
                            className="singlePropertyGridMini"
                            title="საძინებელი"
                          >
                            <FontAwesomeIcon icon={faBed} />: <span>4</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ოთახი">
                            <FontAwesomeIcon icon={faDoorOpen} />:{" "}
                            <span>3</span>
                          </div>
                          <div
                            className="singlePropertyGridMini"
                            title="სართული"
                          >
                            <FontAwesomeIcon icon={faLayerGroup} />:{" "}
                            <span>12/14</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ფართი">
                            <FontAwesomeIcon icon={faClone} />:{" "}
                            <span>
                              120 მ<sup>2</sup>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Main;
