//import tags
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import 'swiper/css';
import 'swiper/css/navigation';

// import recources
import tbilisiImage from '../../assets/image/city.jpg'


function Cityes() {

    return (
        <Container>
            <Row>
                <Col xxl={12}>
                    <section className="citySection">
                        
                        <div className="sectionHeader">
                            <div className="sectionHeaderLeft">
                                <h2>პოპულარული ქალაქები</h2>
                                <p>მოიძიეთ სასურველი განცხადება ქალაქების მიხედვით.</p>
                            </div>
                        </div>
                        
                        <div className="citySlider">
                            <Row>
                                
                                <Col xxl={3}>
                                    <div className="singleCity">
                                        <div className="singleCityImage">
                                            <Image src={tbilisiImage} alt="City image" />
                                        </div>
                                        <div className="singleCityTitle">
                                            <h3>თბილისი</h3>
                                            <p>244 განცხადება</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xxl={3}>
                                    <div className="singleCity">
                                        <div className="singleCityImage">
                                            <Image src={tbilisiImage} alt="City image" />
                                        </div>
                                        <div className="singleCityTitle">
                                            <h3>ბათუმი</h3>
                                            <p>244 განცხადება</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xxl={3}>
                                    <div className="singleCity">
                                        <div className="singleCityImage">
                                            <Image src={tbilisiImage} alt="City image" />
                                        </div>
                                        <div className="singleCityTitle">
                                            <h3>ქუთაისი</h3>
                                            <p>244 განცხადება</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xxl={3}>
                                    <div className="singleCity">
                                        <div className="singleCityImage">
                                            <Image src={tbilisiImage} alt="City image" />
                                        </div>
                                        <div className="singleCityTitle">
                                            <h3>რუსთავი</h3>
                                            <p>244 განცხადება</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xxl={3}>
                                    <div className="singleCity">
                                        <div className="singleCityImage">
                                            <Image src={tbilisiImage} alt="City image" />
                                        </div>
                                        <div className="singleCityTitle">
                                            <h3>თელავი</h3>
                                            <p>244 განცხადება</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xxl={3}>
                                    <div className="singleCity">
                                        <div className="singleCityImage">
                                            <Image src={tbilisiImage} alt="City image" />
                                        </div>
                                        <div className="singleCityTitle">
                                            <h3>ზუგდიდი</h3>
                                            <p>244 განცხადება</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xxl={3}>
                                    <div className="singleCity">
                                        <div className="singleCityImage">
                                            <Image src={tbilisiImage} alt="City image" />
                                        </div>
                                        <div className="singleCityTitle">
                                            <h3>ფოთი</h3>
                                            <p>244 განცხადება</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xxl={3}>
                                    <div className="singleCity">
                                        <div className="singleCityImage">
                                            <Image src={tbilisiImage} alt="City image" />
                                        </div>
                                        <div className="singleCityTitle">
                                            <h3>გორი</h3>
                                            <p>244 განცხადება</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xxl={3}>
                                    <div className="singleCity">
                                        <div className="singleCityImage">
                                            <Image src={tbilisiImage} alt="City image" />
                                        </div>
                                        <div className="singleCityTitle">
                                            <h3>ხაშური</h3>
                                            <p>244 განცხადება</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xxl={3}>
                                    <div className="singleCity">
                                        <div className="singleCityImage">
                                            <Image src={tbilisiImage} alt="City image" />
                                        </div>
                                        <div className="singleCityTitle">
                                            <h3>ახალციხე</h3>
                                            <p>244 განცხადება</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xxl={3}>
                                    <div className="singleCity">
                                        <div className="singleCityImage">
                                            <Image src={tbilisiImage} alt="City image" />
                                        </div>
                                        <div className="singleCityTitle">
                                            <h3>ბორჯომი</h3>
                                            <p>244 განცხადება</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col xxl={3}>
                                    <div className="singleCity">
                                        <div className="singleCityImage">
                                            <Image src={tbilisiImage} alt="City image" />
                                        </div>
                                        <div className="singleCityTitle">
                                            <h3>ბაკურიანი</h3>
                                            <p>244 განცხადება</p>
                                        </div>
                                    </div>
                                </Col>

                            </Row>

                        
                        </div>

                    </section>
                </Col>
            </Row>
        </Container>
    )

}

export default Cityes;