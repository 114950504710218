//import tags
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

function Slider() {

    return (
        <section className="sliderSection">
            <div className="SliderContent">
                <div className="SliderText">
                    <h1>მოძებნე შენი უძრავი ქონება.</h1>
                    <pre>ჩვენ გთავაზობთ მხოლოდ გადამოწმებულ უძრავ ქონებას!</pre>
                </div>
                <div className="sliderSearch">
                    <div className="sliderSearchInput">
                        <input className="mainSearch" name="search" type="text" placeholder="თბილისი, დიდუბე ჩუღურეთი, აკაკი წერეთლის გამზირი" />
                        <button className="searchButton"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                    </div>
                </div>
            </div>
        </section>
    )
    
}

export default Slider;