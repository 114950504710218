//import tags
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';


// import recources
import Cameraimage from '../../assets/image/camera.png'
import Rateimage from '../../assets/image/rating.png'
import Checkingimage from '../../assets/image/checking.png'
import Lowimage from '../../assets/image/low.png'


function Services() {

    return (
        <Container>
            <Row>
                <Col xxl={12}>
                    <section className="serviceSection">
                        
                        <div className="sectionHeader">
                            <div className="sectionHeaderLeft">
                                <h2>ჩვენი სერვისები</h2>
                                <p>გაეცანით ჩვენს პოპულარულ სერვისებს.</p>
                            </div>
                        </div>
                        
                        <Row>
                            
                            <Col xxl={3}>
                                <Link to="/photovideo">
                                    <div className="singleHomeService">
                                        <div className="singleHomeServiceImage">
                                            <Image src={Cameraimage} alt="Service" />
                                        </div>
                                        <h4>ფოტო-ვიდეო გადაღება</h4>
                                    </div>
                                </Link>
                            </Col>

                            <Col xxl={3}>
                                <Link to="/rate">
                                    <div className="singleHomeService">
                                        <div className="singleHomeServiceImage">
                                            <Image src={Rateimage} alt="Service" />
                                        </div>
                                        <h4>შეფასება</h4>
                                    </div>
                                </Link>
                            </Col>

                            <Col xxl={3}>
                                <Link to="/check">
                                    <div className="singleHomeService">
                                        <div className="singleHomeServiceImage">
                                            <Image src={Checkingimage} alt="Service" />
                                        </div>
                                        <h4>ყიდვამდე გადამოწმება</h4>
                                    </div>
                                </Link>
                            </Col>

                            <Col xxl={3}>
                                <Link to="/legalservice">
                                    <div className="singleHomeService">
                                        <div className="singleHomeServiceImage">
                                            <Image src={Lowimage} alt="Service" />
                                        </div>
                                        <h4>იურიდიული მომსახურება</h4>
                                    </div>
                                </Link>
                            </Col>

                        </Row>


                    </section>
                </Col>
            </Row>
        </Container>
    )

}

export default Services;