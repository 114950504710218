//import tags
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";
import { Navigation, A11y } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import 'swiper/css';
import 'swiper/css/navigation';

// import recources
import developer from '../../assets/image/developer.jpg'


function Map() {

    return (
        <Container>
            <Row>
                <Col xxl={12}>
                    <section className="mapSection">

                        <div className="sectionHeader">
                            <div className="sectionHeaderLeft">
                                <h2>აქ საქართველოა</h2>
                                <p>გაიგეთ მეტი საქართველოში მდებარე უძრავი ქონების შესახებ.</p>
                            </div>
                        </div>

                        <div className="mapbox">
                            <svg baseProfile="tiny" className="svgoptimize" fill="#7c7c7c" height="512" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" version="1.2" viewBox="0 0 1000 512" width="1000" xmlns="http://www.w3.org/2000/svg">

                                <path className="showdirection" fill="#EECD78" data-id="dir1" d="M323.6 74.5l-2.1 4.8-1.7 5.3 0.6 5.8 0.1 5.7-2.1 5.8-3 4.9-3.4 3.9-2.9 4.7-7.7 3.3-14.5 2.7-3.5 4.6 2.7 3.4 1.2 4.3 0.8 4.7 1.5 4.4 0.7 4.4-2.3 3.1-1.8 1.2-1.6 1.7-0.1 2.2 1.1 1.9 1.5 9.3-0.2 5.3 0.6 4.9 4.2 7.2 1 8.8-1.3 3.6-2.6 1.7-3.1 2.5-2.6 3.7-11.7 11.4-13.3 7.8-7.3 2.5-5.4 6.7-5.6 3.5-6 2.9-7.5-47.3-2.6-8-3.8-6.8-4.9-5.3-6.1-3.5-11.3-2.1-2.5-1.4-2.4-2.2-3.6-2-3.7-1.5-3-0.4-1.7 0.5-3 1.9-1.9 0.3-1.6-0.9-6.8-10.4-3.4-12.5-6.2-10.8-2.5-5.8-3.7 0.1-5.9 2.6-3.7-1.3-4.4-6.8-2.6-1.6-0.1-1-3.5-6.1-1-0.8-10.8-4.3-14.9-2.1-7 0.4-4.8 1.2-1.8 0.2-1.7-0.7-2.9-2.9-2.2-1.2-2.5-2.7-2.8-1.6-4.7-0.8-12-5.8-5.5-0.6-3.9 5-1.5-1.6-2.2-5.5-1.3-2.7-3.4-3.4-1.5-2.2 0.7-6.5-2.4-6-3.2-5.5-2.4-3-4.6-2.2-8.2-2.4-4.7-4.9-2.2-1-6.8-1.1-4.7-2-3.5-0.7 0.8-3.6 10.1-26.4 2-3.3 3.6-2.4 10-2.7 10.3 0 36.7 13.8 5.9 0.7 4.6-1.2 9.7-5.1 5.4-0.6 4.3 2.2 8.8 6.8 15.2 5.3 4.8 2.8 20.4 16.4 4.6 1.2 15.5-2.1 5 1.1 4.8 2.4 4.6 3.8 5.2 2.3 10.7-2.1 4.5 1.8 1.3 2 2.1 4.8 1.4 2.1 2.2 1.6 7.7 2.5 3.9 2.3 6.6 6.9 4.1 2.2 5.4 0.5 10.9-1.5 5.4 0.6 9.7 2.8 4.7 0.5 10.3-1.2 4.4 0.2 4.3 1.2 4.7 2.3 4.7 1.4 4.8 0.3 4.7-1 11.8-6.5z" id="GEO3015" name="Abkhazia">

                                </path>

                                <text x="150" y="100" className="rangText">აფხაზეთი</text>

                                <path className="showdirection" fill="#EECD78" data-id="dir2" d="M372.3 361.1l2.1 6.2-0.2 6.9-1.7 2.7-0.3 3.7 1 2.9 1.4 2.5 3.9 3.6 3.5 4.1 4.4 7 1.5 4.2-3.2 2.4-1.5 3.3-1.4 8.1-1.5 3.3-3.3 3.4-4.4 6.8-3.1 2-3.8 0.2-26.1-10.2-7.3 0.4-3.5-0.3-2.5-2.3-2.1-1.3-2.3-0.1-4.7 1.4-2.1-1-2.1-0.4-2.1 0.3-2.1 1.1-5.3 1.6-10.6-4.1-6 2.5-2.1 1.5-4.6 6.7-6 5.2-1.4 0.8-1.8-0.8-5.9-5.5-2-0.7-4.4-0.5-1.7-0.7-0.6-1.3 0.2-3.1-1.4-0.8-3.7 1.7-4.5 0.4-1.8-0.4-15.9-6.7 9.8-17.6 4.3-5.9 9-5.9 2.5-6 7-11.5 0.6-5.8 0.9-1.7 2.4-3.4 1.2-3.5 0-16.2 19.4 1 6.2 3.7 0.8 4.2 7.3 3.1 4.7 2.7 4.6 3.6 1.9-0.3 3.9-1.5 7.2-0.9 7.4 4.1 4.4 1.1 10.8-1.7 7.9-5 4.1-1.3 3.3 0.8 5.7 5.9 1.9 1 3.8 0.3z" id="GEO3027" name="Ajaria">
                                </path>

                                <text x="290" y="400" className="rangText">აჭარა</text>

                                <path className="showdirection" fill="#EECD78" data-id="dir3" d="M334.7 301.3l-3.4 5.3 3.6 5.3 7.3 1.3 2.8 0.8 2.4-0.8 4.6-2.9 5.3 1.3 2.9 4.7 1.9 5.9 4.3 2.6 5.3 1.6 3.8 2.8 4.1 2.1 2.9 1.7 2.9 1.5 4.8-0.4 4.5 1.3 2.2 8.7 0.3 10.1-3.8 0.1-3.3 2.2-1.3 3.6-2.5 2-3.4-0.6-3.3-1.2-7.3 0.8-3.8-0.3-1.9-1-5.7-5.9-3.3-0.8-4.1 1.3-7.9 5-10.8 1.7-4.4-1.1-7.4-4.1-7.2 0.9-3.9 1.5-1.9 0.3-4.6-3.6-4.7-2.7-7.3-3.1-0.8-4.2-6.2-3.7-19.4-1 0-0.8-1.8-12.6-0.3-4.6-0.6-2.7-6.1-10.7 3.3-5.2 0.6-3.9 2.1-5.6 9.3-1.7 9.6-0.4 18.4-3.1 8.3 2.1 7.5 5 9.5 1.3 7.9 3.9z" id="GEO3028" name="Guria">
                                </path>

                                <text x="290" y="330" className="rangText">გურია</text>

                                <path className="showdirection" fill="#EECD78" data-id="dir4" d="M475.4 132.2l-4.8 1.9-5.1 7.2-3.3 3.1-0.4 6.4-2.7 4.2-4.1-0.7-9.3-3.6-10.8 1.4-16.1-7.3-2 1-1.8 1.5-4 1.8-8.7-0.3-21.1-5.2-3.7-1.7-3.7-0.1-1.9 3.4-1.5 3.7-1.6 1.3-1.8 0.4-5.6 0.4-4.7 2.7-3.6 7 8.1 7.1 16.7 9.5 6.7 7-3.9 8.4 11.3 22.7-2.9 0.1-2.7 0.8-7.4 0.5-4.6 4.6-2.2 7-2.3 2-1.5 3.1-0.1 5.9-2 4.8-10.4 12.1-6.5 10.7-1.7 5.2-2.8 3.3-1.5 8.4 0.2 10.5-3 6.3-5.9 0.6-7.9-3.9-9.5-1.3-7.5-5-8.3-2.1-18.4 3.1-9.6 0.4-9.3 1.7-2.1 5.6-0.6 3.9-3.3 5.2-4.5-7.9-3.1-3-0.7-3.8-0.3-6.9-1.2-9.1-7.9-27.6-1.6-2.7-4-4-1.1-4.8 6-2.9 5.6-3.5 5.4-6.7 7.3-2.5 13.3-7.8 11.7-11.4 2.6-3.7 3.1-2.5 2.6-1.7 1.3-3.6-1-8.8-4.2-7.2-0.6-4.9 0.2-5.3-1.5-9.3-1.1-1.9 0.1-2.2 1.6-1.7 1.8-1.2 2.3-3.1-0.7-4.4-1.5-4.4-0.8-4.7-1.2-4.3-2.7-3.4 3.5-4.6 14.5-2.7 7.7-3.3 2.9-4.7 3.4-3.9 3-4.9 2.1-5.8-0.1-5.7-0.6-5.8 1.7-5.3 2.1-4.8 4.6-1.7 25.1-2.1 10.2 1.4 8.5 5.7 3.9 4 4.3 3.4 9 5.1 2.9 0.6 2.1-0.9 4-3.8 2.6-1.2 4.7-0.1 6.9-2.2 5.9 0.2 5.7 1.5 4.2 2.7 4.2 4 4.3 2.9 12.7 4.7 0 2.7-1.1 3.4-0.5 3.3 1.4 2.1 7.3 5.2 16 15.2 2.9 1.6z" id="GEO3029" name="Samegrelo-Zemo Svaneti">
                                </path>

                                <text x="285" y="220" className="rangText">სამეგრელო</text>
                                <text x="250" y="250" className="rangText">ზემო სვანეთი</text>


                                <path className="showdirection" fill="#EECD78" data-id="dir5" d="M536.8 241.5l5.2 3.2 5.8 0.9 4.3-1 4.1-2.3 4.8-0.7 3.3 4.1-1.7 6.5-0.2 6.6-11.4 3.1-10 7.2-1.2 5.9 0.9 6.4-0.9 5.3-4 3.8-2 5.8-2.8 5.2-4.8 3.7-4.1 4.1-0.6 8.5-2.3 7.2-5.4 4.8-1 7.7-44.5 18.3-2.5-0.4-2.5-0.9-15.3 1.3-6.4 2.7-6.7-0.1-12.8-2.7-15.7 4-9.2-5.5-0.3-10.1-2.2-8.7-4.5-1.3-4.8 0.4-2.9-1.5-2.9-1.7-4.1-2.1-3.8-2.8-5.3-1.6-4.3-2.6-1.9-5.9-2.9-4.7-5.3-1.3-4.6 2.9-2.4 0.8-2.8-0.8-7.3-1.3-3.6-5.3 3.4-5.3 5.9-0.6 3-6.3-0.2-10.5 1.5-8.4 2.8-3.3 1.7-5.2 6.5-10.7 10.4-12.1 2-4.8 0.1-5.9 1.5-3.1 2.3-2 2.2-7 4.6-4.6 7.4-0.5 2.7-0.8 2.9-0.1 1.3 4.6 0 5.1 1.2 3.9 0.1 4.3 15.9-5.4 3.8 1.6 3.9 0.3 3.3-3.3 3.7-0.2 8.2 5.3 7.5 7.1 1.9 6.3 3.7 3 3.2 0.6 7.1 4 10.7-1 6.2-2.6 5.3-5.3 14.1-7.4 15.8 1.7 15.1-1.2 12.8 4.7z" id="GEO3030" name="Imereti">
                                </path>

                                <text x="400" y="300" className="rangText">იმერეთი</text>


                                <path className="showdirection" fill="#EECD78" data-id="dir6" d="M796.4 431.7l-0.4-3.4-2.2-4.9-1.1-5-2.7-4-4.7-1.3-4.8-0.3-2.6-1.8-1.1-3.9-0.8-5.4 5.1-11.8-2.9-10.7-1.1-6.4-2.6-5.3-5.7-0.7-4.9-3.6-3.3-5.4-3.9-4.4-2-5.9-4.6-2.8 3.9-4.4 4.5-2.7 8.1 5.3 12-1.7-5.4-6.5-7.3-1.9-3.5-0.3-3.4-1.2-1.4-6.2 2.3-5.5-2.6-6.3-0.5-5.7-1.7-7.7 2-4.7 2.3-11.5 2.8-4.5 3.1-10.2 3.6-4.6 3.9-3.5 3.1-0.5 2.9-1.1 1.3-7.8-1.9-8 1.5-4.8 3.3-2.9 2.3-5.2-0.5-6.1 2.1-9.2 4.8-7.2 1.7 3.7 1.5 2.4 4.9 2 6.4-0.9 12-3.6 8.5 0.2 12.5 2.2 11.6 4.6 6.1 7.3-0.5 4.8-17.1 45 1.5 4.7 4.5 2.9 6 2.8 16.3 11.5 10.4 2.7 2 2 4.3 10.5 1.5 2.2 2 1.1 2.8 0.2 3.1-2 2.2 0.2 3.7 2.9 4.7-0.7 1.5 1 1 4.3 3.6 1.7 4.6 0.1 8.7-1.8 3.9-0.4 1.4 0.4 3.9 3.2 6.5 7.8 4.2 2 8.9 0.7 3.6 2.1 3.6 4.9-5.6 11.7-3.2 5.2-6.4 7.9-2.2 1.9-2.4 1.2-3.1 0.3-6.9-1.6-3.3 0.3-2.8 3.1-1.5 6.9 0.1 10.1 1.9 8.9 4 3.4 1.3-1.1 1.8-3.3 2-0.1 0.3 1.1 3.4 6.4 1.4 9.2 1.3 4.3 2.5 3.3 7.8 5.4 1.8 0.8 3 0.8 7 6.5 14.4 6.1 7.3 4.4 2.4 5.5 2.3-0.9 1.2 0.8 1 1.7 2.9 3.3 4.7 7.2 0.9 2.9-1.7 0-1.3 1.6-0.5 5-1.1 2.5-2.9 3.1-1.4 5.3-0.8 6.8-1.3 6.4-2.8 4-3 0.5-2.8-0.8-2.9-0.3-3.1 2-1.7 3.2-0.9 3.1-1.3 2.1-3.2 0.1-2.7-1.6-4.1-5.7-2.5-2.1-8.8-2.9-3.1-1.9-8.8-8-4.3-5.4-2.4-2.3-2.6-1-11.2 0.1-3.3 0.8-7.7 4.9-3 1.1-3.6 0.3-7.1-0.8-22.1-8.1-10.4-6.3-6.1-5.3-3.6-5.7 3.3-4.1 3.4-1.2 2.2-1.2-0.2-1.2-3.5-1.4-9.7-2-21.4-8.4-4.8-4.6-2.2-2.5-2.2-0.4-4.9 1.5-3 0.3-2.3-0.5-7.2-3.7z" id="GEO3033" name="Kakheti">
                                </path>

                                <text x="830" y="370" className="rangText">კახეთი</text>

                                <path className="showdirection" fill="#EECD78" data-id="dir7" d="M791.7 208.1l-4.8 7.2-2.1 9.2 0.5 6.1-2.3 5.2-3.3 2.9-1.5 4.8 1.9 8-1.3 7.8-2.9 1.1-3.1 0.5-3.9 3.5-3.6 4.6-3.1 10.2-2.8 4.5-2.3 11.5-2 4.7 1.7 7.7 0.5 5.7 2.6 6.3-2.3 5.5 1.4 6.2 3.4 1.2 3.5 0.3 7.3 1.9 5.4 6.5-12 1.7-8.1-5.3-4.5 2.7-3.9 4.4 4.6 2.8 2 5.9-7.4 0.4-7.5-1.8-3.2 1.3-2.5 3.7-1.8 5.6-3.5 4.3-9.1-4.8-3.8-0.3-2.3 4.1-4.2 2.7-4.3-0.1-3 6.8-2.9 1.2-1.8 3.2-7.8 4-7-6.3-7-5-8.2-1.1 0-5.2 2.9-3.4-0.5-7.7 4.1-4 5.5-0.2 2-5.7-4-6.3-4.9-5.8-1.2-7.2-1.8-6.2-5.2-2.9-10.6-2.4-5.1-0.2-4.3 3.3-4.4 4-0.7-5.1-4.1-2.3-0.6-3.3 1.3-4 0.3-10.3 2.3-9.8 3.7-2.9 2.3-5.1 3.1-2.4 2.6-3.5-1.2-6.3-2.5-5.3 0.5-6.4-0.3-6-2.1-2.8-2-2-0.6-3.1-0.4-3.2-1-2.1-1.7-1 2.3-7.2 4.8-6-2.3-3.3-2.6-2.3-5-8.9-7-1.9-3.1-4.2-2.1-6 1.7-0.6 4.9-5 2.7-5.7 3.5-4.1 13.2-0.3 6-2 11.6-6.2 4.3-1.2 7.5-0.6 6.3 0.8 6.2 2 5.5 3.2 2.3 1.9 4.4 4.7 1.8 2.7 3.6 11.8 1.9 2 1.3-1.5 2.4-11.1 4.3-9.4 0.9-3.2 3.9-5.2 6.4 1.3 16.3 11.7 2.6 1.3 2.7 0.6 2.8-0.5 5.5-2.4 2.9-0.4 4.8 1.8 4.3 3.8 14.6 17.9 2.8 5.3z" id="GEO3034" name="Mtskheta-Mtianeti">
                                </path>

                                <text x="675" y="270" className="rangText">მცხეთა</text>
                                <text x="665" y="290" className="rangText">მთიანეთი</text>


                                <path className="showdirection" fill="#EECD78" data-id="dir8" d="M584.5 206.8l-0.9 10.3-7.9 5.7-3.3 5.9-2.8 1.3-20.7 1.7-12.1 9.8-12.8-4.7-15.1 1.2-15.8-1.7-14.1 7.4-5.3 5.3-6.2 2.6-10.7 1-7.1-4-3.2-0.6-3.7-3-1.9-6.3-7.5-7.1-8.2-5.3-3.7 0.2-3.3 3.3-3.9-0.3-3.8-1.6-15.9 5.4-0.1-4.3-1.2-3.9 0-5.1-1.3-4.6-11.3-22.7 3.9-8.4-6.7-7-16.7-9.5-8.1-7.1 3.6-7 4.7-2.7 5.6-0.4 1.8-0.4 1.6-1.3 1.5-3.7 1.9-3.4 3.7 0.1 3.7 1.7 21.1 5.2 8.7 0.3 4-1.8 1.8-1.5 2-1 16.1 7.3 10.8-1.4 9.3 3.6 4.1 0.7 2.7-4.2 0.4-6.4 3.3-3.1 5.1-7.2 4.8-1.9 2.9 1.5 42.4 12.1 5.6 1.4 10.3 5.1 2.5 2.2 6.1 7.3 2.8 1.8 13.8 3.4 6.7 3.4 1.6 5.9-3.4 4.9-5.2 3.7-4.2 4.1-0.4 6 4.2 5.3 6.7 1.8 7.3 0.5 6 1.6 3.4 2.6z" id="GEO3035" name="Racha-Lechkhumi-Kvemo Svaneti">
                                </path>

                                <text x="420" y="180" className="rangText">რაჭა</text>
                                <text x="440" y="200" className="rangText">ლეჩხუმი</text>

                                <path className="showdirection" fill="#EECD78" data-id="dir11" d="M756.7 353.4l3.9 4.4 3.3 5.4 4.9 3.6 5.7 0.7 2.6 5.3 1.1 6.4 2.9 10.7-5.1 11.8 0.8 5.4 1.1 3.9 2.6 1.8 4.8 0.3 4.7 1.3 2.7 4 1.1 5 2.2 4.9 0.4 3.4-2.7-1.4-4.9-0.3-4.8 1.7-36.8 30.6-1.8 1.1-3.4 3.1-1.8 1.3-5.6 0.1-14.4-2.2-2.9 2.9 1.3 2.8 5.5 2.7 1.6 2.2-1.5 2.1-3.9 0.5-21.8-0.4-6.2-2.5-3-0.4-3.2 1.5-1.9 2.5-1.8 3.2-2.2 1.9-3.1-0.9-2.1-5-1.6 0.1-4 4.1-3.6 1-3.3-0.2-3.3-1.2-7.9-4.8-1.9-0.2-1.7 0.5-3.7 3.3-3.5 0.6-2.7-1.1-7.2-5-4.1-1.7-1.6 0.1-1.7 1.2-0.5 3.2-0.8 1.9-2.3 2.3-2.9 1.6-3.2 0.6-6.3-1.8-2.9 0.3-6 2.7-3.4 0.9-1.6-10.3-1.6-3.2-1.9-2.8-1.1-8.8 2.2-8.7-0.6-4.3-1.6-3.8-3.1-2-1.1-4.5-0.1-4.4-0.4-4.3-0.6-2.7-1.4-1.2-2.7-5.7-3.9-4.2-7.1-0.4-7.1 1.8-6.6 0.3-5.2-1 1-3.2 2.1-1.7 1.6-4.9 0.9-19.9 3.4-6.2 6.6 1.1 8.9-0.4 7.3-7.8 34.4 3.4 4.6-1.5 4.7-0.8 7 1.7 7.2-1.3 9-4.3 2.7 1.3 2.4 2.9 3.4 1.4 10.4-0.3 2.4-5.2 8.2 1.1 7 5 7 6.3 7.8-4 2.7 0.8 0.4 2.8 2.1 2.7 4.4-0.2 12.1 6.3 3.7 0.3 3.6 0.9 4.3 2.1 5.7 0.4 5.6-1.9 4.3-6.2-1.5-4.9-2.4 1.9-1.9 2.8-3.4 0.9-3.5-0.9-2.6-1.3-2.3-2.1-0.7-3.4-1-3.5-2.9-2.7 0.4-3.4 4.3-4.2 3.5-4.3 1.8-5.6 2.5-3.7 3.2-1.3 7.5 1.8 7.4-0.4z" id="GEO3037" name="Kvemo Kartli">
                                </path>

                                <text x="600" y="430" className="rangText">ქვემო ქართლი</text>

                                <path className="showdirection" fill="#EECD78" data-id="dir9" d="M730.8 366.9l-4.3 4.2-0.4 3.4 2.9 2.7 1 3.5 0.7 3.4 2.3 2.1 2.6 1.3 3.5 0.9 3.4-0.9 1.9-2.8 2.4-1.9 1.5 4.9-4.3 6.2-5.6 1.9-5.7-0.4-4.3-2.1-3.6-0.9-3.7-0.3-12.1-6.3-4.4 0.2-2.1-2.7-0.4-2.8-2.7-0.8 1.8-3.2 2.9-1.2 3-6.8 4.3 0.1 4.2-2.7 2.3-4.1 3.8 0.3 9.1 4.8z" id="GEO3036" name="Tbilisi">
                                </path>

                                <text x="685" y="380" className="rangText">თბილისი</text>


                                <path className="showdirection" fill="#EECD78" data-id="dir12" d="M581.2 374l-7.3 7.8-8.9 0.4-6.6-1.1-3.4 6.2-0.9 19.9-1.6 4.9-2.1 1.7-1 3.2 5.2 1 6.6-0.3 7.1-1.8 7.1 0.4 3.9 4.2 2.7 5.7 1.4 1.2 0.6 2.7 0.4 4.3 0.1 4.4 1.1 4.5 3.1 2 1.6 3.8 0.6 4.3-2.2 8.7 1.1 8.8 1.9 2.8 1.6 3.2 1.6 10.3-3.3 0.8-13.8 0.3-6.4 2.7-6.9 6.1-2.9 1.2-3.7 0.3-24.2-3.4-6.4 0.2-8 3.3-1.5 0.4-2.9-0.4 1.7-5.2-2.1-4.7-4.1-3.7-4-2.4-4.7-1.3-4.3 0.3-13.7 3.9-2.2-1.6-3.5-8.8-3-3.4-3-0.4-3.3 0.4-4-1.3 8.1-4.1 2.1-1.9 2-2.9-0.2-1-4.3-0.6-4.6-2.2-7.2-6.3-10.9-7.5-2.2-2.4-4.4-8.4-2.5-1.9-2.2-2.5-4.4-1.5-1.2-0.7-1.2-2.2-0.8-2.5-1.1-1.3-2 1.3-3.6 4.2-2.8-0.9-2.9-3.1-3.8-2.3 1.3-2 2.9-2.5 1.2-1.4 1.4-5.8 1.2-1.8-2.8-1.4-20.9-1.8-7.5 0.6-3.5 1.2-1.5-4.2-4.4-7-3.5-4.1-3.9-3.6-1.4-2.5-1-2.9 0.3-3.7 1.7-2.7 0.2-6.9-2.1-6.2 7.3-0.8 3.3 1.2 3.4 0.6 2.5-2 1.3-3.6 3.3-2.2 3.8-0.1 9.2 5.5 15.7-4 12.8 2.7 6.7 0.1 6.4-2.7 15.3-1.3 2.5 0.9 2.5 0.4 44.5-18.3 4.1-0.2 9.7 2.3 5.2 2.5 1.4 2.8 0.8 3.2 2 1.7 1.5 2.3 5.1 4.4 4.6 0.8 2.2 5.3 4.8 1.8 5.1 3 5.6-0.9 10-0.1 6.3 7.6z" id="GEO3038" name="Samtskhe-Javakheti">
                                </path>

                                <text x="420" y="400" className="rangText">სამცხე-ჯავახეთი</text>

                                <path className="showdirection" fill="#EECD78" data-id="dir13" d="M669.4 371.3l-2.4 5.2-10.4 0.3-3.4-1.4-2.4-2.9-2.7-1.3-9 4.3-7.2 1.3-7-1.7-4.7 0.8-4.6 1.5-34.4-3.4-6.3-7.6-10 0.1-5.6 0.9-5.1-3-4.8-1.8-2.2-5.3-4.6-0.8-5.1-4.4-1.5-2.3-2-1.7-0.8-3.2-1.4-2.8-5.2-2.5-9.7-2.3-4.1 0.2 1-7.7 5.4-4.8 2.3-7.2 0.6-8.5 4.1-4.1 4.8-3.7 2.8-5.2 2-5.8 4-3.8 0.9-5.3-0.9-6.4 1.2-5.9 10-7.2 11.4-3.1 0.2-6.6 1.7-6.5-3.3-4.1-4.8 0.7-4.1 2.3-4.3 1-5.8-0.9-5.2-3.2 12.1-9.8 20.7-1.7 2.8-1.3 3.3-5.9 7.9-5.7 0.9-10.3 1.8 1.5 2.9 1.4 2.7-0.5 2.2-1.9 3.7-5.1 2-2.2 6.5-3.1 14.3-0.7 5.2-1.5 2.1 6 3.1 4.2 7 1.9 5 8.9 2.6 2.3 2.3 3.3-4.8 6-2.3 7.2 1.7 1 1 2.1 0.4 3.2 0.6 3.1 2 2 2.1 2.8 0.3 6-0.5 6.4 2.5 5.3 1.2 6.3-2.6 3.5-3.1 2.4-2.3 5.1-3.7 2.9-2.3 9.8-0.3 10.3-1.3 4 0.6 3.3 4.1 2.3 0.7 5.1 4.4-4 4.3-3.3 5.1 0.2 10.6 2.4 5.2 2.9 1.8 6.2 1.2 7.2 4.9 5.8 4 6.3-2 5.7-5.5 0.2-4.1 4 0.5 7.7-2.9 3.4 0 5.2z" id="GEO3039" name="Shida Kartli">
                                </path>

                                <text x="550" y="300" className="rangText">შიდა</text>
                                <text x="560" y="320" className="rangText">ქართლი</text>

                                <circle cx="718.8" cy="284.5" id="0">
                                </circle>
                                <circle cx="908.2" cy="350.9" id="1">
                                </circle>
                                <circle cx="557" cy="368.7" id="2">
                                </circle>
                            </svg>
                        </div>

                    </section>
                </Col>
            </Row>
        </Container>
    )

}

export default Map;