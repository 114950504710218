import React, { useState, useEffect } from 'react';
import { useAppContext } from "./../../components/Context";

//import tags
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper/modules';
import { Link } from "react-router-dom";
import axios from 'axios';
import { Container, Row, Col, Image } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'


//import resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faRightFromBracket, faUserTie, faSliders, faHeart, faLocationCrosshairs, faBed, faDoorOpen, faLayerGroup, faEdit, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular, faClone } from '@fortawesome/free-regular-svg-icons';

import 'swiper/css';
import 'swiper/css/navigation';

import Noproperty from "../../assets/image/property.jpg";

//import components
import ProfileMenu from './../../components/dynamic/ProfileMenu';

//api
import { get_user_properties } from "../../api/Api";


function Ads() {

    const baseUrl = "https://reluxapi.myweb.com.ge";

    const { getenumbykey, setFavoritesCount } = useAppContext();

    const [property, setProperty] = useState(false);
    const [queryParams, setQueryParams] = useState({});
    const [totalPages, setTotalPages] = useState(null);
    const [message, setMessage] = useState("");
    const [page, setPage] = useState(1);

    const [currentCurrency, setCurrentCurrency] = useState('GEL');

    const toggleCurrency = (currency) => {
        setCurrentCurrency(currency);
    };

    const updateQueryParams = (key, value) => {
        setQueryParams((prevParams) => {
          const updatedParams = { ...prevParams };
    
          if (value === "") {
            // Remove the key if the value is empty
            delete updatedParams[key];
          } else {
            // Add or update the key with the new value
            updatedParams[key] = value;
          }
    
          return updatedParams;
        });
      };

      useEffect(() => {
        updateQueryParams("page", page.toString());
      }, [page]);

      useEffect(() => {
        if (typeof property == "object" && totalPages == null) {
          setTotalPages(property?.total / property?.per_page);
        }
      }, [property]);

      useEffect(() => {
        const fetchData = async () => {
          try {
            const header = {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }

            get_user_properties(setProperty, header, queryParams);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        if (!property) {
          fetchData();
        }
      }, [property]);
    
      useEffect(() => {
        setProperty(false);
      }, [queryParams]);

    return (
        <div className='main'>

            <section className="breadcumbSection" id="sectionBreadCumb">
                <Container>
                    <Row>
                        <Col xxl={12}>
                            <div className="breadcumb">
                                <div className="breadcumbLeft">
                                    <h1>ჩემი განცხადებები</h1>
                                </div>
                                <div className="breadcumbRight">
                                    <ul>
                                        <li><Link to="/">მთავარი</Link></li>
                                        <li><FontAwesomeIcon icon={faChevronRight} /></li>
                                        <li>ჩემი განცხადებები</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="contactSection">
                <Container>
                    <Row>

                        <Col xxl={4}>
                            <ProfileMenu />
                        </Col>

                        <Col xxl={8}>
                            <div className="profileCard">
                            <h4 className="profileEditBoxTitle">ჩემი განცხადებები</h4>
                            <Row>
                                
                            {typeof property === "object" ? (
                                property.data.map(function (item) {
                                    return (
                                    <Col xl={4}
                                        className="gridView"

                                        key={item.id}
                                    >
                                        <div className="singleProductGridBox">
                                        {message && (
                                            <div className="addedToFavourites">
                                            <h4>{message}</h4>
                                            </div>
                                        )}
                                        <div className="singleProductGridBoxImageBox">
                                            <Link to={`/property/details/` + item.id}>
                                            <Swiper
                                                modules={[Navigation, A11y]}
                                                spaceBetween={5}
                                                slidesPerView={1}
                                                navigation
                                                scrollbar={{ draggable: true }}
                                            >
                                                {item.image1 && item.image1 != null ? (
                                                <SwiperSlide>
                                                    <div className="singleProductGridBoxImage">
                                                    <Image
                                                        src={`${baseUrl}/storage/property/thumbnail/${item.image1}`}
                                                        alt={`${
                                                        typeof item === "object" &&
                                                        item?.transaction_type != null
                                                            ? getenumbykey(
                                                                parse(item.transaction_type)
                                                            )
                                                            : ""
                                                        } ${
                                                        typeof item === "object" &&
                                                        item?.property_type != null
                                                            ? getenumbykey(
                                                                parse(item.property_type)
                                                            )
                                                            : ""
                                                        }`}
                                                    />
                                                    </div>
                                                </SwiperSlide>
                                                ) : (
                                                <SwiperSlide>
                                                    <div className="singleProductGridBoxImage">
                                                    <Image src={Noproperty} alt="Relux" />
                                                    </div>
                                                </SwiperSlide>
                                                )}
                                            </Swiper>
                                            </Link>
                                            {item.statement_type && (
                                            <div
                                                className={`singleProductGridTag ${
                                                item.statement_type === "exclusive"
                                                    ? "exclusiveAd"
                                                    : "reluxAd"
                                                }`}
                                            >
                                                <Link to="/">
                                                <h4>
                                                    {item?.statement_type != null
                                                    ? getenumbykey(
                                                        parse(item.statement_type)
                                                        )
                                                    : ""}
                                                </h4>
                                                </Link>
                                            </div>
                                            )}
                                            
                                        </div>
                                        <div className="singleProductGridBoxContant">
                                            <div className="singleProductGridPrice">
                                            {["GEL", "USD", "EUR"].includes(
                                                currentCurrency
                                            ) && (
                                                <h4>
                                                {item?.[
                                                    `price_${currentCurrency.toLowerCase()}`
                                                ] != null
                                                    ? item[
                                                        `price_${currentCurrency.toLowerCase()}`
                                                    ]
                                                    : ""}
                                                <span>{currentCurrency}</span>
                                                </h4>
                                            )}
                                            <div className="priceBoxGrid">
                                                {["GEL", "USD", "EUR"].map((currency) => (
                                                <div
                                                    key={currency}
                                                    className={`singlePrice ${
                                                    currentCurrency === currency
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    onClick={() => toggleCurrency(currency)}
                                                >
                                                    <p>{currency}</p>
                                                </div>
                                                ))}
                                            </div>
                                            </div>
                                            <div className="propertyGridTags">
                                            <div className="singleTag">
                                                {item?.property_type != null
                                                ? getenumbykey(parse(item.property_type))
                                                : ""}
                                            </div>
                                            <div className="singleTag">
                                                {item?.city != null
                                                ? getenumbykey(parse(item.city))
                                                : ""}
                                            </div>
                                            </div>
                                            <Link to={`/property/details/` + item.id}>
                                            <h1>
                                                {item?.transaction_type != null
                                                ? getenumbykey(
                                                    parse(item.transaction_type)
                                                    )
                                                : ""}{" "}
                                                {item?.property_type != null
                                                ? getenumbykey(parse(item.property_type))
                                                : ""}
                                            </h1>
                                            </Link>
                                            

                                            <div className="singlePropertyGridLocation">
                                            <FontAwesomeIcon
                                                icon={faLocationCrosshairs}
                                            />
                                            <p>
                                                {item?.address != null
                                                ? item.address.length > 50
                                                    ? item.address
                                                        .substring(0, 50)
                                                        .replace(/<[^>]*>/g, "") + "..."
                                                    : item.address.replace(/<[^>]*>/g, "")
                                                : ""}
                                            </p>
                                            </div>
                                            <div className="singlePropertyListMiniBox">
                                            {(() => {
                                                switch (item?.property_type) {
                                                case "land":
                                                    return (
                                                    <div
                                                        className="singlePropertyGridMini"
                                                        title="ფართი"
                                                    >
                                                        <FontAwesomeIcon icon={faClone} />:
                                                        <span>
                                                        {item?.area != null
                                                            ? item.area
                                                            : ""}{" "}
                                                        მ<sup>2</sup>
                                                        </span>
                                                    </div>
                                                    );
                                                case "apartment":
                                                    return (
                                                    <>
                                                        <div
                                                        className="singlePropertyGridMini"
                                                        title="საძინებელი"
                                                        >
                                                        <FontAwesomeIcon icon={faBed} />:
                                                        <span>
                                                            {item?.bedrooms != null
                                                            ? item.bedrooms
                                                            : ""}
                                                        </span>
                                                        </div>
                                                        <div
                                                        className="singlePropertyGridMini"
                                                        title="ოთახი"
                                                        >
                                                        <FontAwesomeIcon
                                                            icon={faDoorOpen}
                                                        />
                                                        :
                                                        <span>
                                                            {item?.rooms != null
                                                            ? item.rooms
                                                            : ""}
                                                        </span>
                                                        </div>
                                                        <div
                                                        className="singlePropertyGridMini"
                                                        title="სართული"
                                                        >
                                                        <FontAwesomeIcon
                                                            icon={faLayerGroup}
                                                        />
                                                        :
                                                        <span>
                                                            {item?.floor != null
                                                            ? item.floor
                                                            : ""}
                                                        </span>
                                                        </div>
                                                        <div
                                                        className="singlePropertyGridMini"
                                                        title="ფართი"
                                                        >
                                                        <FontAwesomeIcon icon={faClone} />
                                                        :
                                                        <span>
                                                            {item?.area != null
                                                            ? item.area
                                                            : ""}{" "}
                                                            მ<sup>2</sup>
                                                        </span>
                                                        </div>
                                                    </>
                                                    );
                                                case "business":
                                                    return (
                                                    <>
                                                        <div
                                                        className="singlePropertyGridMini"
                                                        title="ფართი"
                                                        >
                                                        <FontAwesomeIcon icon={faClone} />
                                                        :
                                                        <span>
                                                            {item?.area != null
                                                            ? item.area
                                                            : ""}{" "}
                                                            მ<sup>2</sup>
                                                        </span>
                                                        </div>
                                                        <div
                                                        className="singlePropertyGridMini"
                                                        title="ოთახი"
                                                        >
                                                        <FontAwesomeIcon
                                                            icon={faDoorOpen}
                                                        />
                                                        :
                                                        <span>
                                                            {item?.rooms != null
                                                            ? item.rooms
                                                            : ""}
                                                        </span>
                                                        </div>
                                                    </>
                                                    );
                                                default:
                                                    return null;
                                                }
                                            })()}
                                            </div>
                                        </div>
                                        </div>
                                    </Col>
                                    );
                                })
                                ) : (
                                <>
                                    <Col xl={4}>
                                    <div className="singleProductGridBox">
                                        <div className="singleProductGridBoxImageBox">
                                        <Skeleton height={240} />
                                        </div>
                                        <div className="singleProductGridBoxContant">
                                        <h1 className="mt-3">
                                            <Skeleton height={25} />
                                        </h1>
                                        <Skeleton className="mt-3" height={20} />
                                        <Skeleton className="mt-3" height={20} />
                                        </div>
                                    </div>
                                    </Col>
                                    <Col xl={4}>
                                    <div className="singleProductGridBox">
                                        <div className="singleProductGridBoxImageBox">
                                        <Skeleton height={240} />
                                        </div>
                                        <div className="singleProductGridBoxContant">
                                        <h1 className="mt-3">
                                            <Skeleton height={25} />
                                        </h1>
                                        <Skeleton className="mt-3" height={20} />
                                        <Skeleton className="mt-3" height={20} />
                                        </div>
                                    </div>
                                    </Col>
                                    <Col xl={4}>
                                    <div className="singleProductGridBox">
                                        <div className="singleProductGridBoxImageBox">
                                        <Skeleton height={240} />
                                        </div>
                                        <div className="singleProductGridBoxContant">
                                        <h1 className="mt-3">
                                            <Skeleton height={25} />
                                        </h1>
                                        <Skeleton className="mt-3" height={20} />
                                        <Skeleton className="mt-3" height={20} />
                                        </div>
                                    </div>
                                    </Col>
                                </>
                                )}

                                <div className="col-xxl-12 mt-5">
                                    <div className="pagination">
                                        <button
                                        onClick={
                                            property.current_page > 1
                                            ? () => setPage(property.current_page - 1)
                                            : null
                                        }
                                        disabled={property.current_page <= 1}
                                        className="pagination-button paginatePrev"
                                        >
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                        </button>
                                        <div className="pagination-pages paginateMiddle">
                                        {typeof totalPages === "number" && totalPages > 0
                                            ? Array.from(
                                                { length: Math.ceil(totalPages) },
                                                (_, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => setPage(index + 1)}
                                                    className={
                                                    "pagination-page paginateSingle " +
                                                    (index + 1 === property.current_page
                                                        ? "active"
                                                        : "")
                                                    }
                                                >
                                                    {index + 1}
                                                </div>
                                                )
                                            )
                                            : ""}
                                        </div>
                                        <button
                                        className="pagination-button paginateNext"
                                        onClick={
                                            property.current_page < Math.ceil(totalPages)
                                            ? () => setPage(property.current_page + 1)
                                            : null
                                        }
                                        disabled={property.current_page > Math.ceil(totalPages)}
                                        >
                                        <FontAwesomeIcon icon={faArrowRight} />
                                        </button>
                                    </div>
                                    </div>
                                

                            </Row>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </div>
        
    )

}

export default Ads;