import LocalizedStrings from 'react-localization';
import ka from './../../locales/ka';
import en from './../../locales/en';
import ru from './../../locales/ru';

let strings = new LocalizedStrings({
    ka: ka,
    en: en,
    ru: ru,
});

export default strings;